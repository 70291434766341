import { Component, OnInit } from '@angular/core';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Device, TimelineCustomSizes } from '@models';
import { WebClient } from '@injectables';

@Component({
  selector: 'app-dialog-custom-sizes',
  templateUrl: './dialog-custom-sizes.component.html',
  styleUrls: ['./dialog-custom-sizes.component.scss'],
})
export class DialogCustomSizesComponent implements OnInit {
  device: Device;
  public sizeOptions: TimelineCustomSizes[] = [];
  public originalSizeOptions: TimelineCustomSizes[] = [];
  public newSize: number = null;
  public newLabel: 'Fines' | 'Custom' | 'Oversize' = 'Custom';
  public newColor: string = '#000';
  public changesMade: boolean = false;

  constructor(
    public readonly fragQuery: FragQueryService,
    public readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly webClient: WebClient
  ) {}

  public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
  public readonly device$ = this.fragQuery.device$;
  public readonly restClient = this.webClient.model.device;

  ngOnInit(): void {
    this.device$.subscribe((device: Device) => {
      this.device = device;
      if (this.device.timelineCustomSizes) {
        this.sizeOptions = JSON.parse(this.device.timelineCustomSizes);
        this.originalSizeOptions = JSON.parse(this.device.timelineCustomSizes);
      } else {
        this.sizeOptions = [];
        this.originalSizeOptions = [];
      }
    });
  }

  hasChanges() {
    return this.changesMade;
  }

  addCustomSizeRange() {
    if (this.newSize != null) {
      const newRange = { size: this.newSize, label: this.newLabel, color: this.newColor };
      this.sizeOptions = [...this.sizeOptions, newRange];
      this.newSize = null;
      this.newLabel = 'Custom';
      this.newColor = '#000';
      this.checkForChanges();
    } else {
      alert('Invalid size range');
    }
  }

  removeSizeRange(index: number) {
    this.sizeOptions.splice(index, 1);
    this.checkForChanges();
  }

  isFinesAvailable() {
    return !this.sizeOptions.some((size) => size.label === 'Fines');
  }

  isOversizedAvailable() {
    return !this.sizeOptions.some((size) => size.label === 'Oversize');
  }

  cancel() {
    this.dialog.closeAll();
  }

  checkForChanges() {
    this.changesMade = JSON.stringify(this.sizeOptions) !== JSON.stringify(this.originalSizeOptions);
  }

  async save() {
    try {
      this.sizeOptions.sort((a, b) => a.size - b.size);
      this.device.timelineCustomSizes = JSON.stringify(this.sizeOptions);
      await this.restClient.save(this.device);
      this.snackBar.open('Settings saved successfully', 'OK', {
        duration: 5000,
        panelClass: ['snackbar-success'],
      });
      this.originalSizeOptions = JSON.parse(JSON.stringify(this.sizeOptions));
      this.changesMade = false;
      this.dialog.closeAll();
      this.fragQuery.trigger$.next();
    } catch (error) {
      console.error('Error saving settings:', error);
    }
  }
}