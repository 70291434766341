<div class="flex-percent center middle full-height full-width">
    <div class="flex-row center middle full-height full-width auth-container container-50">
        <div class="flex-column center">
            <div class="flex-row even">
                <mat-icon class="logo-icon" svgIcon="netralogin"></mat-icon>
            </div>
            <div>
                <login></login>
            </div>
            <div class="footer">
                <p>Copyright &copy; 2024 Strayos</p>
            </div>
        </div>
    </div>
    <div class="right-half full-width full-height container-50">
        <img src="./../../../../assets/mat-icons/netraloginimg.svg" alt="Image" class="full-height full-width">
    </div>
</div>