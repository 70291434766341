import { Component, Input } from '@angular/core';
import { Series } from '@lib/angular/chart/line/chart-line.namespace';
import { op } from '@lib/rxjs';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { fragQuery, User } from '@models';

@Component({
    selector: 'snapshot-card-analysis',
    templateUrl: './snapshot-card-analysis.component.html',
    styleUrls: ['./snapshot-card-analysis.component.scss']
})
export class SnapshotCardAnalysisComponent {
    constructor(
        private readonly fragQuery: FragQueryService,
        private user: User
    ) { }
    @Input() snapType: string = 'analysis';
    @Input() imagesrc: string = '';
    @Input() downloadButton: boolean = false;
    public intervalsArray: any[] = null;
    public currentInterval = this.fragQuery.currentIndex.value;
    public showInterval = false;
    public readonly percents$ = this.fragQuery.newPercents$;
    public readonly intervals$ = new BehaviorSubject<fragQuery.timeline.sieveSizesAtPercents.Interval[]>([]);
    public readonly currentInterval$ = new BehaviorSubject(null);
    unitSystem: string = this.user.particleUnit;
    @Input()
    get series() {
        return this.intervals$.value;
    }
    set series(v) {
        this.intervals$.next(v);
    }
    public readonly deviceName$ = this.fragQuery.device$.pipe(
        op.map(device => device.name)
    );

    @Input()
    get currInterval() {
        return this.currentInterval$.value;
    }
    set currInterval(v) {
        this.currentInterval$.next(v);
    }

    public readonly currentData = this.currentInterval$.pipe(
        filter(interval => interval !== null),
        map(interval => {
            this.showInterval = true;
            return interval;
        })
    );

    getFormattedDateTime(): string {
        const formatMap: Record<string, string> = {
            'Month DD YYYY_h11': 'MMM dd yyyy hh:mm:ss a',
            'MM/DD/YY_h11': 'MM/dd/yy hh:mm:ss a',
            'YY/MM/DD_h11': 'yy/MM/dd hh:mm:ss a',
            'DD/MM/YY_h11': 'dd/MM/yy hh:mm:ss a',
            'DD/MM/YYYY_h11': 'dd/MM/yyyy hh:mm:ss a',
            'Month DD YYYY_h23': 'MMM dd yyyy HH:mm:ss',
            'MM/DD/YY_h23': 'MM/dd/yy HH:mm:ss',
            'YY/MM/DD_h23': 'yy/MM/dd HH:mm:ss',
            'DD/MM/YY_h23': 'dd/MM/yy HH:mm:ss',
            'DD/MM/YYYY_h23': 'dd/MM/yyyy HH:mm:ss',
        };
        const formatKey = `${this.user.dateFormat}_${this.user.timeFormat}`;
        return formatMap[formatKey];
    }

    refresh() {
        this.showInterval = false;
    }

    handleNextClick() {
        if (this.fragQuery.currentIndex.value === 0) {
            this.currentInterval = 0;
        }
        const next = this.currentInterval + 1;
        this.showInterval = false;
        this.currentInterval = next === this.fragQuery.intervalsLength.value ? 0 : next;
        this.fragQuery.currentIndex.next(this.currentInterval)
    }

    handlePreviousClick() {
        if (this.fragQuery.currentIndex.value === 0) {
            this.currentInterval = 0;
        }
        const previous = this.currentInterval - 1;
        this.showInterval = false;
        this.currentInterval = previous < 0 ? this.fragQuery.intervalsLength.value - 1 : previous;
        this.fragQuery.currentIndex.next(this.currentInterval)
    }

    ngOnChanges() {
        this.intervals$.subscribe(intervals => {
            this.intervalsArray = intervals;
        });
    }
}