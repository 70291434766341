<div mat-dialog-header class="title">
	Shift
</div>
<div mat-dialog-content>
	<div class="flex-column">
		<mat-form-field>
			<mat-label>Title</mat-label>
			<input matInput type="text" [(ngModel)]="title" />
		</mat-form-field>
		<mat-form-field>
			<mat-label>Begin</mat-label>
			<time-picker [(value)]="begin"></time-picker>
		</mat-form-field>
		<mat-form-field>
			<mat-label>End</mat-label>
			<time-picker [(ngModel)]="end"></time-picker>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Quantity Type</mat-label>
			<mat-select [(ngModel)]="weighType">
				<mat-option [value]="'volume'">
					Volume
				</mat-option>
				<mat-option [value]="'area'">
					Area
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Chart Type</mat-label>
			<mat-select [(ngModel)]="chartType">
				<mat-option [value]="'column'">
					Column
				</mat-option>
				<mat-option [value]="'line'">
					Line
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Alert Email</mat-label>
			<input matInput type="text" [(ngModel)]="emails">
		</mat-form-field>
	</div>
</div>
<div mat-dialog-actions>
	<button mat-button (click)="cancel()">Cancel</button>
	<button mat-flat-button color="primary" [disabled]="!valid()" (click)="save()">Save</button>
</div>