<div class="flex-row full-width mt ml">
    <mat-form-field class="mat-pickers custom-width-mat-picker large"
        *ngIf="['analytics', 'shift-analytics', 'details', 'video', 'ai-score'].includes(type$ | async)">
        <mat-label> Device </mat-label>
        <mat-select [value]="device$ | async" [disabled]="(chartLines$ | async) === undefined"
            (valueChange)="device$.next($event)">
            <mat-option *ngFor="let device of devices$ | async" [value]="device">
                {{ device.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="mat-pickers custom-width-mat-picker"
        *ngIf="['analytics', 'shift-analytics', 'ai-score'].includes(type$ | async)">
        <mat-label>Duration</mat-label>
        <mat-select [value]="duration$ | async" [disabled]="(chartLines$ | async) === undefined"
            (valueChange)="duration$.next($event)">
            <mat-option *ngFor="let opt of durationOptions|async; let index = index" [value]="opt.key">
                {{ opt.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="(duration$ | async) === 'week'">
        <mat-form-field class="custom-width-mat-picker"
            *ngIf="['analytics', 'shift-analytics', 'ai-score'].includes(type$ | async)">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker" [disabled]="(chartLines$ | async) === undefined">
                <input matStartDate [value]="defaultDateRange?.start" placeholder="Start date">
                <input matEndDate [value]="defaultDateRange?.end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="(duration$ | async) === 'shifts'">
        <mat-form-field class="mat-pickers custom-width-mat-picker"
            *ngIf="['analytics', 'shift-analytics', 'ai-score'].includes(type$ | async)">
            <mat-label> Shift </mat-label>
            <mat-select [disabled]="(chartLines$ | async) === undefined" [value]="shift$ | async"
                (valueChange)="shift$.next($event)">
                <mat-option *ngFor="let shift of shifts$ | async" [value]="shift">
                    {{ shift.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="(duration$ | async) === 'custom'">
        <mat-form-field class="custom-width-mat-picker"
            *ngIf="['analytics', 'shift-analytics', 'ai-score'].includes(type$ | async)">
            <mat-label>Start Date</mat-label>
            <date-picker [value]="begin$ | async" [disabled]="(chartLines$ | async) === undefined"
                (valueChange)="begin$.next($event)">
            </date-picker>
        </mat-form-field>
        <mat-form-field class="mat-pickers custom-width-mat-picker"
            *ngIf="['analytics', 'shift-analytics', 'ai-score'].includes(type$ | async)">
            <mat-label>Start Time</mat-label>
            <time-picker [value]="begin$ | async" [disabled]="(chartLines$ | async) === undefined"
                (valueChange)="begin$.next($event)">
            </time-picker>
        </mat-form-field>
        <mat-form-field class="mat-pickers custom-width-mat-picker"
            *ngIf="['analytics', 'shift-analytics', 'ai-score'].includes(type$ | async)">
            <mat-label>End Date</mat-label>
            <date-picker [value]="end$ | async" [disabled]="(chartLines$ | async) === undefined"
                (valueChange)="end$.next($event)">
            </date-picker>
        </mat-form-field>
        <mat-form-field class="mat-pickers custom-width-mat-picker"
            *ngIf="['analytics', 'shift-analytics', 'ai-score'].includes(type$ | async)">
            <mat-label>End Time</mat-label>
            <time-picker [value]="end$ | async" [disabled]="(chartLines$ | async) === undefined"
                (valueChange)="end$.next($event)">
            </time-picker>
        </mat-form-field>
    </ng-container>

    <mat-form-field class="custom-width-mat-picker"
        *ngIf="['analytics', 'shift-analytics', 'ai-score'].includes(type$ | async) && (duration$ | async) === 'shifts'">
        <mat-label>Select Date</mat-label>
        <date-picker [value]="customShiftDate$ | async" [disabled]="(chartLines$ | async) === undefined"
            (valueChange)="customShiftDate$.next($event)">
        </date-picker>
    </mat-form-field>

    <ng-container>
        <div class="mat-button-toggles-primary" *ngIf="(type$ | async) === 'analytics'">
            <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style" color="primary"
                [value]="weightType$ | async">
                <mat-button-toggle [value]="'area'" (click)="weightType$.next('area')" matTooltip="Quantity : Area"
                    [matTooltipPosition]="'below'"><mat-icon svgIcon="area"
                        class="maticon"></mat-icon></mat-button-toggle>
                <mat-button-toggle [value]="'volume'" (click)="weightType$.next('volume')"
                    matTooltip="Quantity : Volume" [matTooltipPosition]="'below'"><mat-icon svgIcon="cube"
                        class="maticon"></mat-icon></mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div class="mat-button-toggles-primary" *ngIf="(type$ | async) === 'analytics'">
            <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style" color="primary"
                [value]="chartType$ | async" (valueChange)="chartType$.next($event)">
                <mat-button-toggle [value]="'line'" matTooltip="ChartType : Line"
                    [matTooltipPosition]="'below'"><mat-icon>timeline</mat-icon></mat-button-toggle>
                <mat-button-toggle [value]="'series'" matTooltip="ChartType : Column"
                    [matTooltipPosition]="'below'"><mat-icon>equalizer</mat-icon></mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </ng-container>
</div>