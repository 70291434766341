import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { math } from '@lib/math';
import { measurement } from '@lib/measurement';
import { BehaviorSubject, Observable, combineLatest, op } from '@lib/rxjs';
import * as Highcharts from 'highcharts';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { fragQuery } from '../../../../../../shared-models/frag-query';
import { max, min } from '@lib/math/math';
import { User$ } from '@injectables';
import { CustomDXX, DXXData, RockSizePassingPercent } from '@models';

@Component({
    selector: 'psd-chart',
    templateUrl: './psd-chart.component.html',
    styleUrls: ['./psd-chart.component.scss'],
})
export class PSDChartComponent implements OnInit,OnChanges{
	constructor(
		private readonly fragQuery: FragQueryService,
		private readonly user$:User$
	){}
	
	@Input()
    get data() {
        return this.data$.value;
    }
    set data(v) {
        this.data$.next(v);
    }

	@Input() index:number=0;
	@Input() legends:boolean=true;
	@Input() dxxLabels:boolean=true;
	@Input() pointSize: 'large' | 'medium' | 'small' = 'large';
  
	get pointWidth(): {sieveSizeColumnWidth:number,DxxColumnWidth:number} {
	  switch (this.pointSize) {
		case 'large':
		  return {sieveSizeColumnWidth:35,DxxColumnWidth:10}; // Define your 'large' size
		case 'medium':
		  return {sieveSizeColumnWidth:25,DxxColumnWidth:8}; // Define your 'medium' size
		case 'small':
		  return {sieveSizeColumnWidth:15,DxxColumnWidth:5}; // Define your 'small' size
		default:
		  return {sieveSizeColumnWidth:35,DxxColumnWidth:10}; // Default to medium size if size is not recognized
	  }
	}
  

	@Output() dataArrEmitter: EventEmitter<math.Vec2Like[]> = new EventEmitter<math.Vec2Like[]>();
	@Output() percentArrEmitter: EventEmitter<math.Vec2Like[]> = new EventEmitter<math.Vec2Like[]>();
	@Output() dxxArrEmitter: EventEmitter<DXXData[]> = new EventEmitter<DXXData[]>();
	@Output() kuzRamArrEmitter: EventEmitter<math.Vec2Like[]>=new EventEmitter<math.Vec2Like[]>();
	@Output() swebrecArrEmitter: EventEmitter<math.Vec2Like[]>=new EventEmitter<math.Vec2Like[]>();

	public intervalsArray: any[]=null;
	public chartOptions:any=null;
	public Highcharts: typeof Highcharts = Highcharts;
	public customDXX:Array<CustomDXX>;
	public data$= new BehaviorSubject<fragQuery.timeline.sieveSizesAtPercents.Interval[]>(null);
	public chart:Highcharts.Chart;
	public particleSizeUnit:string=''

	ngOnInit() {
		this.setDefaultDxx();
	}

	private setDefaultDxx(){
		if(!this.customDXX)
			this.customDXX=[];
		const defaultDxxIndices=[0,4,7];

		for(const index of defaultDxxIndices){
			const dxxExists=this.customDXX.find(dxx=>dxx.index===index);
			if(!dxxExists)
				this.customDXX.push({
					label:`D${(index+1)*10}`,
					index:index,
				});
		}
	}

	ngOnChanges(){
		this.fragQuery.particleSizeUnit$.subscribe(
			unit=>{
				this.particleSizeUnit=unit;
			}
		),
		this.chartdata$.subscribe(data=>{
			if(data!=undefined && data!==null){
				if(data[this.index]){
					const nbvalues=nbValues(data[this.index].rockSizes)
					this.chartOptions=fragmentationChartOptions(
						data[this.index].rockSizes,
						data[this.index].rockSizeDetails,
						nbvalues.nValue,
						nbvalues.bValue,
						this.particleSizeUnit,
						this.legends,
						this.dxxLabels,
						this.pointWidth.sieveSizeColumnWidth,
						this.pointWidth.DxxColumnWidth,
						data[this.index].dxxValues
					);
					this.percentArrEmitter.emit(rootpercentArr);
					this.dataArrEmitter.emit(rootdataArr);
					this.swebrecArrEmitter.emit(rootswebrecArr);
					this.kuzRamArrEmitter.emit(rootkuzRamArr);
					this.dxxArrEmitter.emit(data[this.index].dxxValues)
				}
			}
		})
	}
	public readonly chartdata$: Observable<{rockSizes:Array<number>,rockSizeDetails:Array<RockSizePassingPercent>,dxxValues:Array<DXXData>}[]> = combineLatest(
		[
			this.data$,
			this.fragQuery.newPercents$
		]
	).pipe(
		op.map(([data,percents])=>{
			return transform(data,percents)
		})
	);
}

export function transform(data:fragQuery.timeline.sieveSizesAtPercents.Interval[],percents:Map<number, string>):{rockSizes:Array<number>,rockSizeDetails:Array<RockSizePassingPercent>,dxxValues:Array<DXXData>}[]{
	if(data!==undefined && data!==null){
		if(data){
			const array:{rockSizes:Array<number>,rockSizeDetails:Array<RockSizePassingPercent>,dxxValues:Array<DXXData>}[]=[];
			data.map(interval=>{
				const sievesizes:Array<number>=[];
				interval.psd.map(psd=>{
					sievesizes.push(psd.size)
				})
				const dxxvalues:Array<DXXData>=[];
				let counter=1;
				percents.forEach((percent,index)=>{
					dxxvalues.push(
						{
							label:`D${index}`,
							value:interval.data[counter],
							index:index,
							color:percent,
							canDelete:false
						}
					)
					counter++;
				})
				let result ={rockSizes:sievesizes,rockSizeDetails:interval.psd,dxxValues:dxxvalues}
				array.push(result)
			})
			return array;
		}
		return null
	}
	return undefined
}
let rootpercentArr:math.Vec2Like[]=[];
let rootdataArr:math.Vec2Like[]=[];
let rootswebrecArr:math.Vec2Like[]=[];
let rootkuzRamArr:math.Vec2Like[]=[];

export function fragmentationChartOptions(
	rockSizes:Array<number>,
	rockSizeDetails:Array<RockSizePassingPercent>,
	nValue:number,
	bValue:number,
	userSmallDistanceUnit:string,
	legends:boolean,
	DxxLabels:boolean,
	sieveSizecolumnWidth:number,
	dxxColumnWidth:number,
	dxxValues:Array<DXXData>
):Highcharts.Options {
		const kuzRamArr:math.Vec2Like[]=[]; // in unit
		const swebrecArr:math.Vec2Like[]=[]; // inunit
		const percentArr:math.Vec2Like[] = [];
		const dataArr:math.Vec2Like[] = []; // in unit
		const predictionDataArray:{x:number, y:number}[]=[];
		const predictionKuzRamArray:{x:number, y:number}[]=[];
		const annotationDXXLabels:Highcharts.AnnotationsLabelsOptions[] = [];
      	const annotationDataDXXLabels:Array<Highcharts.SeriesOptionsType> = [];
		let maxPassingPercent:number=0,tickInterval:number;

		rockSizes=rockSizes.sort((a,b)=>a-b);
		const second = Math.log((rockSizes[rockSizes.length-1] / rockSizes[4]));
		const rocksizeString:string[]=[];
		rockSizes.map(rocksize=>{
			rocksizeString.push(rocksize.toString())
		})
		const initialRockSize=min(rockSizeDetails[0].size,dxxValues[0].value);
		const finalRockSize=max(rockSizeDetails[rockSizeDetails.length-1].size,dxxValues[dxxValues.length-1].value);
		let prev;
		rockSizeDetails.forEach((rockSizeDetail,index)=>{

			const convertedRockSize = parseFloat(rockSizeDetail.size.toFixed(4));
			if(index>0){
				prev=rockSizeDetails[index-1].passing_percent
			}else{
				prev=0
			}
			
			if(rockSizeDetails[index-1]){
				rockSizeDetail.passing_percent=(rockSizeDetail.passing_percent&&rockSizeDetail.passing_percent!=0)?rockSizeDetail.passing_percent:(rockSizeDetails[index-1].passing_percent?rockSizeDetails[index-1].passing_percent:0);
			}
			
			percentArr.push({
				x: convertedRockSize,
				y: parseFloat((rockSizeDetail.passing_percent-prev).toFixed(2))
			})

			maxPassingPercent=parseFloat((rockSizeDetail.passing_percent-prev).toFixed(2))>maxPassingPercent?parseFloat((rockSizeDetail.passing_percent-prev).toFixed(2)):maxPassingPercent

			dataArr.push({
				x: convertedRockSize,
				y: rockSizeDetail.passing_percent
			});
			swebrecArr.push({
				x: convertedRockSize,
				y: calculateSwebRec(convertedRockSize,rockSizes[rockSizes.length-1], second, bValue)
			});
			kuzRamArr.push({
				x: convertedRockSize,
				y: calculateKuzRam(convertedRockSize,rockSizes[4], nValue)
			});
		});
		rootdataArr=dataArr;
		rootpercentArr=percentArr;
		rootswebrecArr=swebrecArr;
		rootkuzRamArr=kuzRamArr;

		tickInterval=Number(maxPassingPercent)/10;

		dxxValues.forEach((dxx,index)=>{
			annotationDXXLabels.push(
				{
				  point: {
					xAxis: 0,
					yAxis: 0,
					x: dxx.value,
					y: (maxPassingPercent-10)
				  },
				  text: dxx.label,
				}
			);
			if(DxxLabels){
				annotationDataDXXLabels.push(
					{
						data: [{ x: dxx.value, y:95}],
						name: dxx.label,
						color: dxx.color,
						pointWidth: dxxColumnWidth,
						type:'column',
						dataLabels:{
							enabled: true,
							y: 200-(dxx.index+1*30),
							style: {
								fontSize: '13px',
								fontFamily: 'Lato, sans-serif'
							},
							format:dxx.label
						},
						enableMouseTracking:false,
					}
				);
			}else{
				annotationDataDXXLabels.push(
					{
						data: [{ x: dxx.value, y:95}],
						color: dxx.color,
						name: dxx.label,
						pointWidth: dxxColumnWidth,
						type:'column',
						enableMouseTracking:false,
					}
				);
			}
		});

		let seriesData:Array<Highcharts.SeriesOptionsType> = [
			{
				yAxis:1,
				name: 'Histogram',
				color: "#5d7af660",
				type:'column',
				data: percentArr,
			},{
				type: 'line',
				data: dataArr,
				name: 'Actual',
				color: "black",
				lineWidth: 3,
			},{
				type: 'line',
				data: kuzRamArr,
				name: 'Kuz-Ram',
				color: "#5DF675",
				lineWidth: 3,
			},{
				type: 'line',
				data: swebrecArr,
				name: 'Swebrec',
				color: "#F65D5D",
				lineWidth: 3,
			},
		];

		if(predictionDataArray.length > 0 && predictionKuzRamArray.length > 0) {
			seriesData = seriesData.concat(
				{
					type: 'line',
					data: predictionDataArray,
					name: 'xp-frag Prediction',
					color: "black",
					lineWidth: 3,
				},
				{
					type: 'line',
					data: predictionKuzRamArray,
					name: 'Kuz-Ram Prediction',
					color: "black",
					lineWidth: 3,
				}
			);
		}

		seriesData = seriesData.concat(annotationDataDXXLabels);
		let chartOptions:Highcharts.Options = {
			chart: {
				type: 'column',
				backgroundColor: 'none',
			},

			title: {
				text: ''
			},

			credits: {
				enabled: false
			},

			legend: {
				verticalAlign: 'top',
				alignColumns: false,
				itemDistance: 10,
				itemStyle: { fontWeight: 'normal', color: '#777'  },
				enabled:legends,

			},

			exporting: {
				enabled: false
			},

			xAxis: {
				title: { text: `Sieve Size (${userSmallDistanceUnit})` },
				min: initialRockSize || 10,
				max: finalRockSize || 10000,
				labels: {
					formatter: (formatterObject)=>''+formatterObject.value,
					style:{
						color:'#777'
					}
				},
			},
			
			yAxis: [
				{
					tickInterval: 10,
					min: 1,
					max: 100,
					title: {
						useHTML: true,
						text: '<strong>Actual</strong> % Passing'
					},
					labels: {
						style: {
							color: '#777'
						}
					}
				},
				{
					tickInterval: tickInterval,
					min: 0,
					max: maxPassingPercent,
					title: { 
						useHTML:true,
						text:'<strong>Histogram</strong> % Passing'
					},
					labels: {
						style: {
							color: '#777'
						}
					},
					opposite:true,
			
				},
				
			],
			
			plotOptions:{
				series:{
					marker:{
						enabled:false
					}
				},
				column:{
					borderWidth:0.1,
					pointPadding:0,
					groupPadding:0,
					pointWidth: sieveSizecolumnWidth,
				},
			},
			tooltip: {
				animation: false,
				followPointer: true,
				useHTML: true,
				formatter:function(tooltip:Highcharts.Tooltip) {
					const rockSize = `Seive Size: ${measurement.toDecimalString(+this.x,2)} ${userSmallDistanceUnit}`;
					return this.points.reduce(function (s, point:Highcharts.TooltipFormatterContextObject,index:number,tooltipContextArr) {
						const symbols=['●','●','♦','■'];
						if (!point.series.chart.legend.group) return null;
						const fontSize=index===0?20:16;
						const legendSymbol = `<span width='20' height='20' style="font-size:${fontSize}px;color:${point.color}">${symbols[index]}</span>`;

						return `
						  ${s} <br/>
						  <span>
						  	<span>${legendSymbol}</span>
							<span>${point.series.name}: ${point.y.toFixed(2)}%</span>
						  </span>
						`;
					  }, rockSize);
				},
				shared: true
			},
			annotations: [{
				labelOptions: {
					backgroundColor: 'rgba(255,255,255,0.5)',
					verticalAlign: 'top',
					allowOverlap: true,
					borderWidth: 0,
				},
				labels: annotationDXXLabels,
				draggable:'',
			}],
			series: seriesData
		};
	return chartOptions;
}

export function calculateKuzRam(rockSize: number, d50: number, nValue: number): number {
	return 100 * (1 - Math.exp(-1 * Math.pow(rockSize / d50, nValue) * Math.log(2)));
}

export function calculateSwebRec(rockSize: number, maxRockSize: number, second: number, bValue: number): number {
	return 100 / (1 + Math.pow(Math.log(maxRockSize / rockSize ) / second, bValue));
}

export function nbValues(rockSizes: Array<number>) {
	const nValue = rockSizes[7] > rockSizes[4] ? 0.842/(Math.log(rockSizes[7]) - Math.log(rockSizes[4])) : 2.0; // Got from white paper
	const bValue = 2 * Math.log(2) * nValue * Math.log(rockSizes[rockSizes.length - 1] / rockSizes[4]);

	return {nValue, bValue};
}