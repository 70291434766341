<h4 mat-dialog-title>Download CSV</h4>
<div mat-dialog-content class="csv-dialog-container" *ngIf="['shift-analytics'].includes(type$ | async)">
  <div class="flex-column full-width align-center">
    <mat-form-field class="mat-pickers custom-width-mat-picker">
      <mat-label>Select Data Type</mat-label>
      <mat-select [(value)]="psdDataFormatInCSV">
        <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" (click)="downloadCsv()">Download CSV</button>
</mat-dialog-actions>