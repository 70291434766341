import { Component } from '@angular/core';
import { DateTime } from '@lib/date-time';
import { ActivatedRoute, Router } from '@angular/router';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { BehaviorSubject, Observable, combineLatest, firstValueFrom } from 'rxjs';
import { op } from '@lib/rxjs';
import * as Highcharts from 'highcharts/highstock';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { User$ } from '@injectables';
import { generateMergedTimelineChartLines, generateScoreTimelineChartLines, generateTimelineChartLines } from '../chart-transforms';
import { fragQuery } from '@models';

@Component({
  selector: 'ai-score',
  templateUrl: './ai-score.component.html',
  styleUrls: ['./ai-score.component.scss'],
})
export class AiScoreComponent {
  constructor(
    public readonly fragQuery: FragQueryService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly user$: User$,
    private dialog: DialogService,
  ) {}
  
  public timelineChartType: string = 'default';
  public Highcharts: typeof Highcharts = Highcharts;
  public readonly currentInterval = new BehaviorSubject(null);

  public readonly chartLines$: Observable<Highcharts.Options> = combineLatest([
    this.fragQuery.newPercents$,
    this.fragQuery.timelineSieveSizesAtPercents$,
    this.fragQuery.addOnTimelineSieveSizesAtPercents$,
    this.fragQuery.particleSizeUnit$,
  ]).pipe(
    op.map(([percents, input, input2, unit]) => {
      return generateTimelineChartLines(
        'line',
        percents,
        input,
        unit,
        true,
        true,
        true,
        true,
        this.addData.bind(this),
        this.onPlotClick.bind(this)
      );
    })
  );

  public readonly scoreChartLines$: Observable<Highcharts.Options> =
    combineLatest([
      this.fragQuery.timelineQuantity$,
      this.fragQuery.addOnTimelineQuantity$,
    ]).pipe(
      op.map(([input, addOn]) =>
        generateScoreTimelineChartLines(
          'line',
          input,
          this.addData.bind(this),
          `AI Score & Image Quality (%)`,
          true,
          true,
          true,
          true
        )
      )
    );

  public readonly mergedChartLines$: Observable<Highcharts.Options> =
    combineLatest([
      this.fragQuery.newPercents$,
      this.fragQuery.timelineSieveSizesAtPercents$,
      this.fragQuery.timelineQuantity$,
      this.fragQuery.particleSizeUnit$,
    ]).pipe(
      op.map(([percents, input, scoreInput, unit]) =>
        generateMergedTimelineChartLines(
          percents,
          input,
          scoreInput,
          unit,
          this.addData.bind(this),
          this.onPlotClick.bind(this)
        )
      )
    );

  addData(type: string) {
    this.fragQuery.addDataAt.next(type);
  }

  onPlotClick(time: number) {
    firstValueFrom(this.fragQuery.timelineSieveSizesAtPercents$).then(
      (input) => {
        let nearestInterval: fragQuery.timeline.sieveSizesAtPercents.Interval | null =
          null;
        let minDiff = Infinity;

        input.intervals.forEach((interval) => {
          const intervalBegin = interval.begin.valueOf();
          const intervalEnd = interval.end.valueOf();
          const diff = Math.min(
            Math.abs(intervalBegin - time),
            Math.abs(intervalEnd - time)
          );

          if (diff < minDiff) {
            minDiff = diff;
            nearestInterval = interval;
          }
        });

        if (nearestInterval) {
          this.currentInterval.next(nearestInterval);
        }
      }
    );
  }

  public toggleChart() {
    this.timelineChartType =
      this.timelineChartType === 'default' ? 'merged' : 'default';
  }
}
