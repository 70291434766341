import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from './login.service';
import { storageLocal } from "@lib/storage-local";
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private userService: LoginService,
    private snackBar: MatSnackBar
  ) {}

  authError:boolean = false;

  intercept(req: HttpRequest<any>, next: HttpHandler) {    
    return next.handle(req).pipe(
        catchError((error) => {
          if (error.status === 401 && !this.authError) {
            this.authError = true;           
            let snackbar = this.snackBar.open('Unauthorized! Please log in again.', 'OK', {
              panelClass: ['snackbar-error']
            });
            snackbar.afterDismissed().subscribe(closed=>{
              window.location.href='./login';
            })
          }
          return throwError(error);
        })
      );
    }
  }