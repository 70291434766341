<mat-nav-list class="nav-list">
    <div class="logo">
        <mat-icon class="logo-icon" svgIcon="strayos_white"></mat-icon>
    </div>
    <b><p class="textlogo">Netra</p></b>
    <mat-list-item class="matlist" [routerLinkActive]="['matlist-active']" [routerLink]="['home']" matTooltip="Home" [matTooltipPosition]="'right'">
        <mat-icon svgIcon="home" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
    </mat-list-item>
    <mat-list-item class="matlist" [routerLink]="['analytics']" [routerLinkActive]="['matlist-active']" matTooltip="Analytics" [matTooltipPosition]="'right'">
        <mat-icon svgIcon="analysis" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
    </mat-list-item>
    <mat-list-item class="matlist" [routerLink]="['details']" [routerLinkActive]="['matlist-active']" matTooltip="PSD Details" [matTooltipPosition]="'right'">
        <mat-icon svgIcon="psd" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
    </mat-list-item>
    <mat-list-item class="matlist" [routerLink]="['shift-analytics']" [routerLinkActive]="['matlist-active']" matTooltip="Shift Analytics" [matTooltipPosition]="'right'">
        <mat-icon svgIcon="shift" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
    </mat-list-item>
    <mat-list-item *ngIf="showVideoTab" class="matlist" [routerLink]="['video']" [routerLinkActive]="['matlist-active']" matTooltip="Video" [matTooltipPosition]="'right'">
        <mat-icon svgIcon="play" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
    </mat-list-item>
    <mat-list-item class="matlist" [routerLink]="['manage']" [routerLinkActive]="['matlist-active']" matTooltip="Settings" [matTooltipPosition]="'right'">
        <mat-icon svgIcon="settings" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
    </mat-list-item>
    <mat-list-item  class="matlist" [routerLink]="['user/api-settings']" [routerLinkActive]="['matlist-active']" matTooltip="Data API's settings" [matTooltipPosition]="'right'">
        <mat-icon svgIcon="api" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
    </mat-list-item>
    <mat-list-item *ngIf="isClientAdmin || isSuperAdmin" class="matlist" [routerLink]="['ai-score']" [routerLinkActive]="['matlist-active']"
        matTooltip="AI Score" [matTooltipPosition]="'right'">
        <mat-icon svgIcon="score" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
    </mat-list-item>
</mat-nav-list>