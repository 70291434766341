import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './old-ui/page-not-found/page-not-found.component';
import { HomeComponent } from './new-ui/home/home.component';
import { LogDetailsComponent } from './new-ui/log-details/log-details.component';
import { AnalysisComponent } from './new-ui/analysis/analysis.component';
import { LiveTagSettingsComponent } from './new-ui/manage/live-tag-settings/live-tag-settings.component';
import { UserPreferencesComponent } from './new-ui/user/user-preferences/user-preferences.component';
import { LoginComponent } from './new-ui/auth/login/login.component';
import { PasswordRecoveryComponent } from './new-ui/auth/password-recovery/password-recovery.component';
import { NewPasswordComponent } from './new-ui/auth/new-password/new-password.component';
import { ManageComponent } from './new-ui/manage/manage.component';
import { Path } from 'three';
import { userProvider } from '@injectables';
import { UserComponent } from './new-ui/user/user.component';
import { ApiSettingsComponent } from './new-ui/user/api-settings/api-settings.component';
import { FragCameraReportComponent } from './new-ui/reports/frag-camera-report/frag-camera-report.component';
import { ShiftAnalyticsComponent } from './new-ui/shift-analytics/shift-analytics.component';
import { ReportsComponent } from './new-ui/reports/reports.component';
import { AggregatedDataReportComponent } from './new-ui/reports/aggregated-data-report/aggregated-data-report.component';
import { VideoStreamingComponent } from './new-ui/video-streaming/video-streaming.component';
import { AiScoreComponent } from './new-ui/ai-score/ai-score.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        component: HomeComponent,
    },
    {
        path: 'analytics',
        component: AnalysisComponent,
    },
    {
        path: 'manage',
        component: ManageComponent,
    },
    {
        path: 'details',
        component: LogDetailsComponent,
    },
    {
        path:'report',
        component:ReportsComponent,
        children:[
            {
                path:'data-interval',
                component:FragCameraReportComponent
            },
            {
                path:'time-interval',
                component:AggregatedDataReportComponent
            }
        ]
    },
    {
        path:'shift-analytics',
        component:ShiftAnalyticsComponent
    },
    {
        path: 'ai-score',
        component: AiScoreComponent,
    },
    {
        path: 'user',
        component:UserComponent,
        children:[
            {
                path:'preferences',
                component:UserPreferencesComponent
            },
            {
                path:'api-settings',
                component:ApiSettingsComponent
            },
        ]
    },
    {
        path:'video',
        component:VideoStreamingComponent
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'recover-password',
        component: PasswordRecoveryComponent,
    },
    {
        path: 'new-password',
        component: NewPasswordComponent,
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        pathMatch: 'full'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
