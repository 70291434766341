<div mat-dialog-content>
  <div class="flex-row">
    <div class="icon-box" (click)="reportExport()">
      <mat-icon svgIcon="pdf"></mat-icon>
      <span>Get Report</span>
    </div>
    <a *ngIf="token$|async" target="api" [href]="apiUrl$|async">
      <div class="icon-box">
        <mat-icon style="color:#707FFF">api</mat-icon>
        <span>Get API</span>
      </div>
    </a>
    <div class="icon-box" (click)="openCSVDialog()">
      <mat-icon svgIcon="csv"></mat-icon>
      <span>Get CSV</span>
    </div>
  </div>
</div>