import { Component, HostBinding, OnInit } from '@angular/core';
import { LoginService, NavigationRoute$, User$ } from '@injectables';
import { Location } from '@angular/common';
import { op } from '@lib/rxjs';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { LocalDeviceService } from 'src/injectables/local-device.service';
import { Device } from '@models';
import { FormControl } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { storageLocal } from '@lib/storage-local';
import { HomeFragQueryService } from 'src/injectables/home.service';
import { environment } from '@environment';
import { PendingRequestInterceptor } from 'src/injectables/pending-request.interceptor';
import { PendingRequestService } from 'src/injectables/pending-request.service';


@Component({
    selector: 'app-new-ui',
    templateUrl: './new-ui.component.html',
    styleUrls: ['./new-ui.component.scss']
})
export class NewUiComponent implements OnInit {
    isFragQueryPending$ = this.pendingRequestService.isFragQueryPending$;

    constructor(
        private readonly route$: NavigationRoute$,
        private location: Location,
        private dialog: DialogService,
        private loginService: LoginService,
        private readonly localDevice: LocalDeviceService,
        public readonly user$: User$,
        private overlay: OverlayContainer,
        private readonly fragQuery: HomeFragQueryService,
        private pendingRequestService: PendingRequestService
    ) { }

    public readonly allDeviceSeries$ = this.fragQuery.allDeviceData$;
    public readonly devices$ = this.fragQuery.devices$;
    public showDarkMode: boolean = environment.showDarkMode;

    switchTheme = new FormControl(storageLocal.get('theme') === 'theme-dark' ? 'theme-dark' : 'theme-light');
    @HostBinding('class')
    public className = storageLocal.get('theme') || 'theme-light';

    public darkClass = 'theme-dark';
    public lightClass = 'theme-light';

    ngOnInit(): void {
        this.switchTheme.valueChanges.subscribe((currentMode) => {
            this.className = currentMode ? this.darkClass : this.lightClass;
            storageLocal.set('theme', this.className);
            if (currentMode) {
                this.overlay.getContainerElement().classList.add(this.darkClass);
            } else {
                this.overlay.getContainerElement().classList.remove(this.lightClass);
            }
        });
    }

    toggleTheme() {
        const isDarkMode = this.className === this.darkClass;
        this.className = isDarkMode ? this.lightClass : this.darkClass;
        storageLocal.set('theme', this.className);
        if (isDarkMode) {
            this.overlay.getContainerElement().classList.remove(this.darkClass);
            this.overlay.getContainerElement().classList.add(this.lightClass);
        } else {
            this.overlay.getContainerElement().classList.remove(this.lightClass);
            this.overlay.getContainerElement().classList.add(this.darkClass);
        }
    }

    refresh() {
        this.fragQuery.refresh();
    }

    public readonly userName$ = this.user$
        .pipe(op.map(user => user?.displayName()));

    public readonly email$ = this.user$
        .pipe(op.map(user => user?.email));

    public readonly deviceUnregistered$ = this.localDevice.localDevice$
        .pipe(op.map(device => device === 'unregistered'));
    public readonly device$ = this.localDevice.localDevice$
        .pipe(
            op.map(device => device instanceof Device ? device : null));

    public registerDevice() {
        return this.localDevice.register();
    }
    openReportProperties() {
        this.dialog.openReportProperties();
    }
    activeUser: boolean = true;

    public logout() {
        this.loginService.logout();
    }

    public readonly type$ = this.route$.pipe(
        op.map(route => route[0])
    );

    back(): void {
        this.location.back();
    }
}
