import { Component,Input, SimpleChanges } from '@angular/core';
import { chart } from '@lib/angular/chart';
import { DateTime } from '@lib/date-time';
import { op, taste } from '@lib/rxjs';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, map, Observable } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { fragQuery } from '../../../../../../shared-models/frag-query';
import { User } from '@models';
import { DataPoint, generateChartLines, generateTimelineChartLines } from '@app/new-ui/chart-transforms';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts/highstock';
import { combineInput } from '@app/new-ui/analysis/analysis.component';
@Component({
    selector: 'snapshot-card-home',
    templateUrl: './snapshot-card-home.component.html',
    styleUrls: ['./snapshot-card-home.component.scss'],
})
export class SnapshotCardHomeComponent {
    constructor(
        private readonly fragQuery: FragQueryService,
        private readonly user: User,
        private readonly router: Router
    ) { }

    @Input()
    get timer() {
        return this.timer$.value;
    }
    set timer(v) {
        this.timer$.next(v);
    }
    @Input() public deviceName: string = '';
    @Input() public series: fragQuery.timeline.sieveSizesAtPercents.Response = null;

    public intervalsArray: any[];
    public arrayLength=0;
    public currentImgIndex = 0;
    public showInterval = false;
    public readonly timer$ = new BehaviorSubject('');
    public readonly currentInterval = new BehaviorSubject(null);
    public readonly percents$ = new BehaviorSubject(this.fragQuery.newPercents$.value);
    public xHighlight: DateTime.Range = null;
    public unitSystem: string = this.user.particleUnit;
    public readonly deviceName$ = this.fragQuery.device$.pipe(
        op.map((device) => device.name)
    );
    public Highcharts: typeof Highcharts = Highcharts;
    public chartOptions:Highcharts.Options;

    public readonly currentData = this.currentInterval.pipe(
        filter((interval) => interval !== null),
        map((interval) => {
            this.showInterval = true;
            return interval;
        })
    );

    public onPlotMouseMove(evt: chart.line.PlotEvent) {
        if (evt.series) {
            const input = taste(this.fragQuery.timelineSieveSizesAtPercents$);
            if (input) {
                const dataPoint = <DataPoint>(
                    evt.series.data[Math.floor(evt.dataIndex + 0.5)]
                );
                if (dataPoint) {
                    const interval = dataPoint[2];
                    if (interval) {
                        this.xHighlight = new DateTime.Range(interval.begin, interval.end);
                    }
                }
            }
        }
    }

    getFormattedDateTime(): string {
        const formatMap: Record<string, string> = {
            'Month DD YYYY_h11': 'MMM dd yyyy hh:mm:ss a',
            'MM/DD/YY_h11': 'MM/dd/yy hh:mm:ss a',
            'YY/MM/DD_h11': 'yy/MM/dd hh:mm:ss a',
            'DD/MM/YY_h11': 'dd/MM/yy hh:mm:ss a',
            'DD/MM/YYYY_h11': 'dd/MM/yyyy hh:mm:ss a',
            'Month DD YYYY_h23': 'MMM dd yyyy HH:mm:ss',
            'MM/DD/YY_h23': 'MM/dd/yy HH:mm:ss',
            'YY/MM/DD_h23': 'yy/MM/dd HH:mm:ss',
            'DD/MM/YY_h23': 'dd/MM/yy HH:mm:ss',
            'DD/MM/YYYY_h23': 'dd/MM/yyyy HH:mm:ss',
        };
        const formatKey = `${this.user.dateFormat}_${this.user.timeFormat}`;
        return formatMap[formatKey];
    }

    refresh() {
        this.showInterval = false;
    }
    setDevice(){
        let url = '/analytics#';
        url += JSON.stringify({ device: this.series.deviceId });
        this.router.navigateByUrl(url);
    }

    handleNextClick() {
        const next = this.currentImgIndex + 1;
        this.showInterval = false;
        this.currentImgIndex = next === this.arrayLength? 0 : next;
    }

    handlePreviousClick() {
        const previous = this.currentImgIndex - 1;
        this.showInterval = false;
        this.currentImgIndex = previous < 0 ? this.arrayLength - 1 : previous;
    }

    public readonly series$ = this.percents$.pipe(
        op.map((percents) => {
            if(this.series){
                return generateChartLines(percents, this.series);
            }else{
                return null;
            }
        }),
        op.shareReplay(1)
    )

    public readonly chartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnit$,
    ]).pipe(
        op.tap(([percents,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([percents, input,input2,unit]) => {
            return generateTimelineChartLines('line',percents, this.series, unit,false,false,true,true,this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    addData(type:string){
        this.fragQuery.addDataAt.next(type);
    }

    async onPlotClick (time:number){
        let dataPoint;
        for(let index= 0; index < this.series.intervals.length; index++) {
            const interval=this.series.intervals[index];
            if(interval.begin.valueOf()<=time){
                dataPoint=interval;
            }else{
                break;
            }
        }
        if(dataPoint){this.currentInterval.next(dataPoint)};
    }
    
    public readonly intervals$ = this.percents$.pipe(
        op.map(percents =>{
            if(this.series && this.series.intervals){
                return this.series.intervals
            }else{
                return null;
            }
        })
    )

    ngOnChanges(changes: SimpleChanges) {
        if (changes['series'] && changes['series'].currentValue) {
            if(this.series && this.series!==null){
                this.arrayLength=this.series.intervals.length
                this.intervalsArray = this.series.intervals
            }
          }
    }
}