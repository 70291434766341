<div>
    <mat-card-header class="flex-row center">
        <div>
            <mat-card-title class="title" (click)="setDevice()" matTooltip='See Analytics' [matTooltipPosition]="'above'"> {{ deviceName }} Snapshot</mat-card-title>
            <mat-card-subtitle class="subtitle">Auto updated {{timer$|async}} ago</mat-card-subtitle>
        </div>
    </mat-card-header>
    <div *ngIf="(series$|async) && (series$|async).length===0" class="container centered-content">
        <div>
            <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
        </div>
        <div>No Data Found</div>
    </div>
    <div *ngIf="(series$|async) && (series$|async).length>0" class="flex-wrapper flex-percent">
        <camera-snapshot class="flex-row align-center center" [intervals]="(intervals$|async)"
            [currentInterval]="(currentInterval|async)" [totalIntervals]="arrayLength" (nextClick)="handleNextClick()"
            (previousClick)="handlePreviousClick()" (refreshClick)="refresh()">
        </camera-snapshot>
        <div *ngIf="showInterval===false">
            <div *ngFor="let interval of intervalsArray; let i = index">
                <mat-card-content *ngIf="i==currentImgIndex" class="flex-row wrap around">
                    <div class="blocks-container">
                        <card-square-blocks [title]="'Begin'" [size]="'large'" [warn]="false">
                            {{(intervalsArray[currentImgIndex].begin)|date:getFormattedDateTime()}}</card-square-blocks>
                        <card-square-blocks [title]="'End'" [size]="'large'" [warn]="false">
                            {{(intervalsArray[currentImgIndex].end)|date:getFormattedDateTime()}}</card-square-blocks>
                        <div *ngFor="let keys of (percents$|async).keys(), let j=index">
                            <card-square-blocks [title]="'D'+keys" [size]="'large'" [warn]="false">
                                {{(intervalsArray[currentImgIndex].data[keys/10])| unitConversion:  unitSystem}}
                            </card-square-blocks>
                        </div>
                    </div>
                </mat-card-content>
            </div>
        </div>
        <div *ngIf="(currentData|async) && showInterval===true">
            <mat-card-content class="flex-row wrap around">
                <div class="blocks-container">
                    <card-square-blocks [title]="'Begin'" [size]="'large'" [warn]="false">
                        {{((currentData|async).begin)|date:getFormattedDateTime()}}</card-square-blocks>
                    <card-square-blocks [title]="'End'" [size]="'large'" [warn]="false">
                        {{((currentData|async).end)|date:getFormattedDateTime()}}</card-square-blocks>
                    <div *ngFor="let keys of (percents$|async).keys(), let j=index">
                        <card-square-blocks [title]="'D'+keys" [size]="'large'" [warn]="false">
                            {{((currentData|async).data[keys/10])| unitConversion:  unitSystem }}
                        </card-square-blocks>
                    </div>
                </div>
            </mat-card-content>
        </div>
    </div>
 
    <percent-selector *ngIf="(series$|async) && (series$|async).length>0" 
        [value]="percents$ | async" [small]="true" (valueChanged)="percents$.next($event)">
    </percent-selector>
    <div class="container no-scroll">
    </div>
    <div class="container no-scroll">
    <figure class="highcharts-figure full-width" *ngIf="(series$|async) && (series$|async).length>0">
        <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="chartLines$|async">
        </highcharts-chart>
    </figure>
    </div>
</div>