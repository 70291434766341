<model-table
	[model]="model"
	[restClient]="restClient"
	[editDialog]="editDialog"
	[deletable]="deletable"
	[addable]="addable"
	[filter]="[{
		field: selected,   
		value: SearchText
	}]"
>	
<div class="settings">
	<mat-form-field>
		<mat-label>Select option</mat-label>
		<mat-select [(ngModel)]="selected">
		  <mat-option value="">None</mat-option>
		  <mat-option value="name">Name</mat-option>
		  <mat-option value="type">Type</mat-option>		  
		</mat-select>
	</mat-form-field>&nbsp;
	<mat-form-field appearance="outline">
	<mat-label>Search item</mat-label>
	<input
	  matInput
	  type="text"
	  [(ngModel)]="SearchText"	  
	/>
	</mat-form-field>
</div>
	<ng-template model-table-header>
		<th>
			Client
		</th>
		<th>
			Name
		</th>
		<th>
			UUID
		</th>
		<th>
			Type
		</th>
		<th>
			Notes
		</th>
		<th>
			Iot Name
		</th>
		<th>
			Threshold
		</th>
		<th>
			Slab Diameter
		</th>
		<th>
			Density
		</th>
		<th>
			Alert Emails
		</th>
	</ng-template>

	<ng-template
		model-table-row
		let-record="record"
	>
		<td>
			{{(clients$|async).get(record.clientId)?.name ?? '\<uknown\>'}}
		</td>
		<td>
			{{record.name}}
		</td>
		<td>
			{{record.uuid}}
		</td>
		<td>
			{{typeOptions[record.type]}}
		</td>
		<td>
			<button mat-icon-button [disabled]="!record.notes" (click)="openNotes(record)"><mat-icon>open_in_new</mat-icon></button>
		</td>
		<td>
			{{record.iotName}}
		</td>
		<td>
			<measurement type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [value]="record.threshold"></measurement>
		</td>
		<td>
			<measurement type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [value]="record.slabDiameter"></measurement>
		</td>
		<td>
			<measurement type="largeDensity" [unitSystem]="(quantityUnitSystem$|async)" [value]="record.density"></measurement>
		</td>
		<td>
			<mat-chip-set *ngIf="record.alertEmail" class="mat-chip-list-stacked">
				<mat-chip *ngFor="let chip of getEmails(record.alertEmail)" class="chip" >
					{{chip}}
				</mat-chip>
			</mat-chip-set>
		</td>
	</ng-template>
	<ng-template
		model-table-actions
		let-record="record"
	>
		<button mat-stroked-button class="action-button" (click)="setLiveTag(record)"><mat-icon class="mat-icon-svg">tag</mat-icon></button>		
	</ng-template>
</model-table>
