import { Component, Input } from '@angular/core';
import { combineInput, combineQunatityInput } from '@app/new-ui/analysis/analysis.component';
import { generatePayloadTimelineChartLines, generateTimelineChartLines, generateTimelineQualtityChartLines } from '@app/new-ui/chart-transforms';
import { chart } from '@lib/angular/chart';
import { DateTime } from '@lib/date-time';
import { op } from '@lib/rxjs';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import * as Highcharts from 'highcharts/highstock';

@Component({
  selector: 'app-quantity-timeline-page',
  templateUrl: './quantity-timeline-page.component.html',
  styleUrls: ['./quantity-timeline-page.component.scss'],
})
export class QuantityTimelinePageComponent {
  constructor(public fragQuery: FragQueryService) {}

  @Input() timelineData: chart.line.Series[];
  @Input() startDate: DateTime;
  @Input() endDate: DateTime;

  public readonly particleSizeUnit$ = this.fragQuery.particleSizeUnit$;
  public readonly currentInterval = new BehaviorSubject(null);
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options;
  public readonly chartType$ = this.fragQuery.chartType$;
  public readonly yAxisTitle$ = this.fragQuery.QuantityTitle$;

  addData(type: string) {
    this.fragQuery.addDataAt.next(type);
  }

  async onPlotClick(time: number) {
    const input = await firstValueFrom(
      this.fragQuery.timelineSieveSizesAtPercents$
    );
    let dataPoint;
    for (let index = 0; index < input.intervals.length; index++) {
      const interval = input.intervals[index];
      if (interval.begin.valueOf() <= time) {
        dataPoint = interval;
      } else {
        break;
      }
    }
    if (dataPoint) {
      this.currentInterval.next(dataPoint);
    }
  }

  public readonly chartLines$: Observable<Highcharts.Options> = combineLatest([
    this.fragQuery.newPercents$,
    this.fragQuery.timelineSieveSizesAtPercents$,
    this.fragQuery.addOnTimelineSieveSizesAtPercents$,
    this.fragQuery.particleSizeUnit$,
  ]).pipe(
    op.tap(([percents, input, addOn]) =>
      combineInput(input, addOn, this.fragQuery.addDataAt.value)
    ),
    op.tap((input) => {
      if (input[1] && input[1].intervals) {
        this.fragQuery.totalIntervalLength.next(
          input[1].end.valueOf() - input[1].begin.valueOf()
        );
        this.fragQuery.intervalsLength.next(input[1].intervals.length);
      }
    }),
    op.map(([percents, input, input2, unit]) => {
      return generateTimelineChartLines('line',percents,input,unit,false,false,false,false,this.addData.bind(this),this.onPlotClick.bind(this));
    })
  );

  public readonly quantityChartLines$: Observable<Highcharts.Options> =
    combineLatest([
      this.fragQuery.timelineQuantity$,
      this.fragQuery.addOnTimelineQuantity$,
      this.yAxisTitle$,
    ]).pipe(
      op.tap(([input, addOn, title]) =>
        combineQunatityInput(input, addOn, this.fragQuery.addDataAt.value)
      ),
      op.map(([input, addOn, title]) =>
        generateTimelineQualtityChartLines('line',input,this.addData.bind(this),title,false,false,false,false)
      )
    );
    public readonly payloadChartLines$:Observable<Highcharts.Options>= combineLatest([
      this.fragQuery.timelineQuantity$,
      this.fragQuery.addOnTimelineQuantity$,
      this.fragQuery.payloadUnitAbbreviation$
  ]).pipe(
      op.tap(([input,addOn,abbreviation])=>combineQunatityInput(input,addOn,this.fragQuery.addDataAt.value)),
      op.map(([input,addOn,abbreviation])=>generatePayloadTimelineChartLines('line',input,this.addData.bind(this),`Payload (${abbreviation})`,false,false,false,false))
  )
}