<div mat-dialog-content>
	<div *ngIf="input.title" class="title">
		{{ input.title }}
	</div>
	<mat-form-field>
		<input matInput type="text" [(ngModel)]="value" />
		<mat-label>{{input.label}}</mat-label>
	</mat-form-field>
</div>
<div mat-dialog-actions>
	<button mat-button>Cancel</button>
	<button mat-flat-button color="primary" [mat-dialog-close]="value">Save</button>
</div>