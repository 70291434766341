import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { User } from '@models';
import { environment } from '@environment';
import { iterate } from '@lib/iterate';
import { math } from '@lib/math';
import * as htmlToPdf from '@utilities/html-to-pdf';
import { ReportPageContainerComponent } from './page-container/report-page-container.component';
import { InReport, ReportService } from './report.service';
import { cleanFilename, downloadContent } from '@lib/dom/download';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { NavigationRoute$ } from '@injectables';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [
    {
      provide: InReport,
      useFactory: () => true
    },
  ],
})
export class ReportComponent implements OnChanges, OnInit {
  private reportType: string;
  @Input() title = '';
  @Input() get orientation() { return this.report.orientation; }
  set orientation(v: 'portrait' | 'landscape') { this.report.orientation = v; }
  @ViewChild('pageContainer') pageContainer: ReportPageContainerComponent;

  public readonly pageSizes = Object.keys(htmlToPdf.pageSizes);

  public readonly zoomOptions = [
    ['Fit', 'fit'],
    ['Rows 2', 'rows2'],
    ...[...iterate.numbers(1, 2, 0.1, true)].map(v => [math.round(v * 100).toFixed(0) + '%', v])
  ];
  public readonly begin$ = this.fragQuery.begin$;
  public readonly end$ = this.fragQuery.end$;
  public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
  public isDevMode = false;
  public isMapInteractionOn = false;
  public selectedPageSize = 'LETTER';
  private deviceName: string = '';
  public intervals: number = 0;
  public zoom: ReportPageContainerComponent['zoom'] = 'fit';
  public fileType: string = null;
  public psdDataFormatInCSV: 'single-row' | 'multiple-row' = 'single-row';

  constructor(
    protected readonly dialog: DialogService,
    protected readonly user: User,
    public readonly report: ReportService,
    public readonly route$: NavigationRoute$,
    public readonly fragQuery: FragQueryService,
  ) {
    this.isDevMode = !environment.production;
  }
  public ngOnInit() {
    this.fileType = 'PDF';
    this.selectedPageSize = 'A4';

    this.fragQuery.device$.subscribe(
      device => {
        this.deviceName = device.name
        this.intervals = device.targetIntervals
      }
    )
    this.initializeReport();
  }

  private initializeReport() {
    this.reportType = 'Fragmentation Camera';
    this.report.init(this.reportType);
  }

  public toggleFileType(type: string) {
    this.fileType = type;
  }

  public ngOnChanges() {
    this.report.title = this.title;
  }

  public downloadReady() {
    return this.report.htmlToPdfLoaded;
  }

  public updateInteraction() {
    this.isMapInteractionOn = !this.isMapInteractionOn;
  }

  private filename(ext: string) {
    return cleanFilename(`${this.deviceName}_report_${(new Date).toDateString()}`, ext);
  }

  public async downloadPdf() {
    const progress$ = new BehaviorSubject<number>(0);
    const message$ = new BehaviorSubject<string>('Scanning...')
    const blob = await this.dialog.progress(this.pdfBlob(progress$, message$), progress$, message$);
    if (blob) {
      downloadContent(this.filename('pdf'), blob, 'application/pdf');
      this.dialog.closeAll();
    }
    else
      this.dialog.message('failed to download please try again')
  }

  private async pdfBlob(progress$: BehaviorSubject<number>, message$: BehaviorSubject<string>) {
    if (await this.pageContainer.pdfBlob(progress$)) {
      message$.next('Preparing Download...')
      return await this.pageContainer.pdfBlob(progress$);
    } else {
      return this.retry(progress$);
    }
  }

  private async retry(progress$: BehaviorSubject<number>) {
    if (await this.pageContainer.pdfBlob(progress$)) {
      return await this.pageContainer.pdfBlob(progress$)
    } else {
      return null;
    }
  }
}
