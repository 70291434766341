<div mat-dialog-header class="title">
	Device
</div>
<div mat-dialog-content>
	<div class="flex-column">
		<mat-form-field>
			<mat-label>Client</mat-label>
			<input
				type="text"
				matInput
				[matAutocomplete]="auto"
				[disabled]="(clients$|async).length===0"
				[value]="(initialClient$|async)?.name"
			/>
			<mat-autocomplete
				#auto="matAutocomplete"
				[autoActiveFirstOption]="true"
				[autoSelectActiveOption]="true"
				[displayWith]="clientDisplayWith"
				(optionActivated)="client=$event?.option?.value"
				(optionSelected)="client=$event?.option?.value"
			>
				<mat-option
					*ngFor="let client of (clients$|async)"
					[value]="client"
				>
					{{client.name}}
				</mat-option>
			</mat-autocomplete>			
		</mat-form-field>
		<mat-form-field>
			<mat-label>Type</mat-label>
			<mat-select [(ngModel)]="type">
				<mat-option *ngFor="let item of typeOptions" [value]="item[0]">
					{{item[1]}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Name</mat-label>
			<input matInput type="text" [(ngModel)]="name"/>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Notes</mat-label>
			<textarea matInput [(ngModel)]="notes"></textarea>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Threshold</mat-label>
			<measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="threshold"></measurement-input>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Slabs Diameter</mat-label>
			<measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="slabDiameter"></measurement-input>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Density</mat-label>
			<measurement-input type="largeDensity" [unitSystem]="(quantityUnitSystem$|async)" [(ngModel)]="density"></measurement-input>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Alert Email</mat-label>
			<input matInput type="text" [(ngModel)]="alertEmail">
		</mat-form-field>
	</div>
</div>
<div mat-dialog-actions>
	<button mat-button (click)="cancel()">Cancel</button>
	<button mat-flat-button color="primary" [disabled]="!valid()" (click)="save()">Save</button>
</div>
