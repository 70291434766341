<!-- <banner></banner>

<div class="main" role="main">
	<navigation *ngIf="(user$|async)">
		<router-outlet *ngIf="(user$|async)"></router-outlet>
	</navigation>
	<login *ngIf="(user$|async)===null" class="centered"></login>
</div>

<div *ngIf="!(serverReachable$|async)" class="offline-blocker">
	<div>
		Server not reachable<br/>
		<br/>
		Check Internet
	</div>
</div> -->

<!------------------------ new ui------------- -->

<app-new-ui *ngIf="(serverReachable$|async) && (user$|async) && (type$|async)!=='report'"></app-new-ui>
<auth *ngIf="(serverReachable$|async) && (user$|async)===null" class="auth-module"></auth>
<reports *ngIf="(type$|async)==='report' && (user$|async)" class="full-height full-width">{{type$|async}}</reports>
<!-- <aggregated-data-report *ngIf="(type$|async)==='report' && (user$|async)" class="full-height full-width">{{type$|async}}</aggregated-data-report> -->

<div *ngIf="!(serverReachable$|async)" class="offline-blocker">
	<div>
		Server not reachable
		<br/>
		Check Internet
	</div>
</div>