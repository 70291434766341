import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService,User$} from '@injectables';
import {User} from '@models';
import {DialogService} from '../../../old-ui/dialog/dialog.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls:['./login.component.scss']
})
export class LoginComponent{
	constructor(
		private readonly router:Router,
		private readonly loginService:LoginService,
		private readonly dialog:DialogService,
		public readonly user$:User$,
	){}

	public email='';
	public password='';
	public errors:string[];
	passwordVisible: boolean = false;

	togglePasswordVisibility() {
		this.passwordVisible = !this.passwordVisible;
	}

	public async login(){
		this.errors=null;
		const response=await this.dialog.await(this.loginService.login(this.email,this.password));		
		
		if(response instanceof User){
			this.router.navigateByUrl('/home');
		}else{
			this.errors=Object.values(response.errors).map(v=>v.toString());
		}
	}

	public loginOnEnter(evt:KeyboardEvent){
		if(evt.code==='Enter')
			this.login();
	}

	public continue(){
		this.router.navigateByUrl('/home');
	}

	public logout(){
		this.loginService.logout();
	}
}
