import { Component } from '@angular/core';
import { LoginService } from '@injectables';
@Component({
  selector: 'manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent {
  constructor(public loginService: LoginService) {}

  public isSuperAdmin = this.loginService.isUserSuperAdmin();
  public isUserClientAdmin = this.loginService.isUserClientAdmin();
}