import { Component, Input } from '@angular/core';
import { combineInput } from '@app/new-ui/analysis/analysis.component';
import { generateCustomTimelineChartLines, generateTimelineChartLines } from '@app/new-ui/chart-transforms';
import { chart } from '@lib/angular/chart';
import { DateTime } from '@lib/date-time';
import { op } from '@lib/rxjs';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import * as Highcharts from 'highcharts/highstock';

@Component({
  selector: 'app-custom-timeline-page',
  templateUrl: './custom-timeline-page.component.html',
  styleUrls: ['./custom-timeline-page.component.scss'],
})
export class CustomTimelinePageComponent {
  constructor(public fragQuery: FragQueryService) { }

  @Input() timelineData: chart.line.Series[];
  @Input() startDate: DateTime;
  @Input() endDate: DateTime;

  public readonly particleSizeUnit$ = this.fragQuery.particleSizeUnit$;
  public readonly currentInterval = new BehaviorSubject(null);
  public readonly device$ = this.fragQuery.device$;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options;
  public customTimelineChartType: 'range' | 'size' = 'size';
  public readonly chartType$ = this.fragQuery.chartType$;

  addData(type: string) {
    this.fragQuery.addDataAt.next(type);
  }

  async onPlotClick(time: number) {
    const input = await firstValueFrom(
      this.fragQuery.timelineSieveSizesAtPercents$
    );
    let dataPoint;
    for (let index = 0; index < input.intervals.length; index++) {
      const interval = input.intervals[index];
      if (interval.begin.valueOf() <= time) {
        dataPoint = interval;
      } else {
        break;
      }
    }
    if (dataPoint) {
      this.currentInterval.next(dataPoint);
    }
  }

  public readonly customRangeTimelineChartLines$: Observable<Highcharts.Options> =
    combineLatest([
      this.fragQuery.customSizes$,
      this.fragQuery.timelineSieveSizesAtPercents$,
      this.fragQuery.addOnTimelineSieveSizesAtPercents$,
      this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
      op.tap(([sizes, input, addOn]) =>
        combineInput(input, addOn, this.fragQuery.addDataAt.value)
      ),
      op.tap((input) => {
        if (input[1] && input[1].intervals) {
          this.fragQuery.totalIntervalLength.next(
            input[1].end.valueOf() - input[1].begin.valueOf()
          );
          this.fragQuery.intervalsLength.next(input[1].intervals.length);
        }
      }),
      op.map(([sizes, input, input2, unit]) => {
        return generateCustomTimelineChartLines('range','line',sizes,input,unit,false,false,false,this.addData.bind(this),this.onPlotClick.bind(this));
      })
    );

  public readonly customTimelineChartLines$: Observable<Highcharts.Options> =
    combineLatest([
      this.fragQuery.customSizes$,
      this.fragQuery.timelineSieveSizesAtPercents$,
      this.fragQuery.addOnTimelineSieveSizesAtPercents$,
      this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
      op.tap(([sizes, input, addOn]) =>
        combineInput(input, addOn, this.fragQuery.addDataAt.value)
      ),
      op.tap((input) => {
        if (input[1] && input[1].intervals) {
          this.fragQuery.totalIntervalLength.next(
            input[1].end.valueOf() - input[1].begin.valueOf()
          );
          this.fragQuery.intervalsLength.next(input[1].intervals.length);
        }
      }),
      op.map(([sizes, input, input2, unit]) => {
        return generateCustomTimelineChartLines('size','line',sizes,input,unit,false,false,false,this.addData.bind(this),this.onPlotClick.bind(this));          
      })
    );
}
