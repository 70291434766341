import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PendingRequestService {
  private pendingRequestsSubject = new BehaviorSubject<boolean>(false);
  private fragQueryPendingSubject = new BehaviorSubject<boolean>(false);

  isPendingRequest$ = this.pendingRequestsSubject.asObservable();
  isFragQueryPending$ = this.fragQueryPendingSubject.asObservable();

  setPendingRequestsStatus(isPending: boolean) {
    this.pendingRequestsSubject.next(isPending);
  }

  setFragQueryPendingStatus(isPending: boolean) {
    this.fragQueryPendingSubject.next(isPending);
  }
}
