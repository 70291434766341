import { Component, OnInit, } from '@angular/core';
import { chart } from '@lib/angular/chart';
import { BehaviorSubject, Observable, combineLatest, firstValueFrom, map } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { combineInput, combineQunatityInput, getSummaryData } from '../analysis/analysis.component';
import { op } from '@lib/rxjs';
import { generateChartLines, generateCustomTimelineChartLines, generatePayloadTimelineChartLines, generateQuantityChartLines, generateTimelineChartLines, generateTimelineQualtityChartLines } from '../chart-transforms';
import { fragQuery } from '../../../../../shared-models/frag-query';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { DXXData, Device, RockSizePassingPercent, User } from '@models';
import * as Highcharts from 'highcharts/highstock';
import { math } from '@lib/math';
import { fragmentationChartOptions } from '../reports/frag-camera-report/frag-camera-report.component';
import { highcharts } from '@lib/highcharts';
import { measurement } from '@lib/measurement';
import { MeasurementTypeToUnit } from '@lib/angular/measurement/measurement-type-to-unit';
import { User$ } from '@injectables';

@Component({
    selector: 'app-shift-analytics',
    templateUrl: './shift-analytics.component.html',
    styleUrls: ['./shift-analytics.component.scss']
})

export class ShiftAnalyticsComponent implements OnInit {
    constructor(
        public readonly fragQuery: FragQueryService,
        private readonly dialog: DialogService,
        public readonly user$: User$,
        private readonly measurementTypeToUnit: MeasurementTypeToUnit
    ) { }

    public selectedDevice: Device;
    public density: number;
    public slabCount: number = 0;
    public slabs: number;
    public slabDiameter: number;
    public quantityUnit: string
    public quantityChartType: 'volume' | 'payload' = 'volume';
    public timelineChartType:'default'|'custom'='default'
    public customTimelineChartType:'range'|'size'='size'

    public readonly durationOptions = this.fragQuery.durationOptions;
    public readonly duration$ = this.fragQuery.duration$;
    public readonly newPercents$ = this.fragQuery.newPercents$;
    public readonly devices$ = this.fragQuery.devices$;
    public readonly device$ = this.fragQuery.device$;
    public readonly begin$ = this.fragQuery.begin$;
    public readonly end$ = this.fragQuery.end$;
    public readonly shifts$ = this.fragQuery.shifts$;
    public readonly shift$ = this.fragQuery.shift$;
    public readonly chartType$ = this.fragQuery.chartType$;
    public readonly weightType$ = this.fragQuery.weightType$;
    public readonly particleSizeUnit$ = this.fragQuery.particleSizeUnit$;
    public readonly currentInterval = new BehaviorSubject(null);
    public readonly yAxisTitle$ = this.fragQuery.QuantityTitle$;
    public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
    public readonly quantityUnitSystem$ = this.fragQuery.QuantityUnitSystem$;
    public readonly totalPayload$ = this.fragQuery.totalPayload$;
    public readonly totalWeight$ = this.fragQuery.totalWeight$;
    public readonly payloadUnitAbbr$ = this.fragQuery.payloadUnitAbbreviation$;

    public Highcharts: typeof Highcharts = Highcharts;
    public chartOptions: highcharts.Options;
    tableData: Map<string, {
        dataArr: math.Vec2Like[];
        percentArr: math.Vec2Like[];
        dxxArr: DXXData[];
    }> = new Map();

    ngOnInit(): void {
        this.device$.subscribe(device => {
            this.selectedDevice = device;
            this.slabDiameter = device.slabDiameter
            this.density = device.density
        });

        this.user$.subscribe(user => {
            this.quantityUnit = user.quantityUnit;
        });

        this.fragQuery.timelineSieveSizesAtPercents$.subscribe(data => {
            let totalSlabs = 0;

            data?.intervals.forEach(interval => {
                if (interval.slabs !== undefined && interval.slabs !== null) {
                    totalSlabs += interval.slabs;
                }
            });
            this.slabs = totalSlabs
        });
    }

    public accumulatedData$: Observable<fragQuery.timeline.sieveSizesAtPercents.Interval> = this.fragQuery.timelineSieveSizesAtPercents$.pipe(
        op.map((input) => getSummaryData(input))
    )

    isTruck(): boolean {
        return this.selectedDevice && this.selectedDevice.type === 'truck';
    }
    isConveyor(): boolean {
        return this.selectedDevice && this.selectedDevice.type === 'conveyor';
    }

    countSlabs(input: fragQuery.timeline.sieveSizesAtPercents.Response, threshold: number = 12): number {
        if (!input || !input.summary || !input.summary.psd) {
            return 0;
        }
        return input.summary.psd.filter(rock => rock.size > threshold).length;
    }

    addData(type: string) {
        this.fragQuery.addDataAt.next(type);
    };

    openCustomSizerangeDialog(){
        this.dialog.openCustomSizeSettings();
    }

    async onPlotClick (time:number){
        const input = await firstValueFrom(
            this.fragQuery.timelineSieveSizesAtPercents$
        );
        let dataPoint;
        for(let index= 0; index < input.intervals.length; index++) {
            const interval=input.intervals[index];
            if(interval.begin.valueOf()<=time){
                dataPoint=interval;
            }else{
                break;
            }
        }
        if(dataPoint){this.currentInterval.next(dataPoint)};
    }

    public readonly chartLines$: Observable<Highcharts.Options> = combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnit$
    ]).pipe(
        op.tap(([percents, input, addOn]) => combineInput(input, addOn, this.fragQuery.addDataAt.value)),
        op.tap(input => {
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([percents, input, input2, unit]) => {
            this.generateTablesData(input, percents);
            return generateTimelineChartLines('line', percents, input, unit, true, true, true,true, this.addData.bind(this));
        })
    )

    public readonly chartSeries$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnit$,
    ]).pipe(
        op.tap(([percents,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([percents, input,input2,unit]) => {
            return generateTimelineChartLines('series', percents, input, unit, true, true, true, true, this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly customTimelineChartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.customSizes$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
        op.tap(([sizes,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([sizes, input,input2,unit]) => {
            return generateCustomTimelineChartLines('size','line',sizes, input, unit,true,true,true,this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly customTimelineChartSeries$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.customSizes$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
        op.tap(([sizes,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([sizes, input,input2,unit]) => {
            return generateCustomTimelineChartLines('size','series',sizes, input, unit,true,true,true,this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly customRangeTimelineChartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.customSizes$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
        op.tap(([sizes,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([sizes, input,input2,unit]) => {
            return generateCustomTimelineChartLines('range','line',sizes, input, unit,true,true,true,this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly customRangeTimelineChartSeries$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.customSizes$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
        op.tap(([sizes,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([sizes, input,input2,unit]) => {
            return generateCustomTimelineChartLines('range','series',sizes, input, unit,true,true,true,this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )
    
    public readonly quantityChartLines$: Observable<Highcharts.Options> = combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$,
        this.yAxisTitle$
    ]).pipe(
        op.tap(([input, addOn, title]) => combineQunatityInput(input, addOn, this.fragQuery.addDataAt.value)),
        op.map(([input, addOn, title]) => generateTimelineQualtityChartLines('line', input, this.addData.bind(this), title, true, true, true, true)))

    public readonly quantityChartSeries$: Observable<Highcharts.Options> = combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$,
        this.yAxisTitle$
    ]).pipe(
        op.tap(([input, addOn, title]) => combineQunatityInput(input, addOn, this.fragQuery.addDataAt.value)),
        op.map(([input, addOn, title]) => generateTimelineQualtityChartLines('series', input, this.addData.bind(this), title, true, true, true, true)))

    public readonly payloadChartSeries$: Observable<Highcharts.Options> = combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$,
        this.yAxisTitle$
    ]).pipe(
        op.tap(([input, addOn, abbreviation]) => combineQunatityInput(input, addOn, this.fragQuery.addDataAt.value)),
        op.map(([input, addOn, abbreviation]) => generatePayloadTimelineChartLines('series', input, this.addData.bind(this),`Payload (${abbreviation})`,true,true,true,true))
    )

    public readonly payloadChartLines$: Observable<Highcharts.Options> = combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$,
        this.fragQuery.payloadUnitAbbreviation$
    ]).pipe(
        op.tap(([input, addOn, abbreviation]) => combineQunatityInput(input, addOn, this.fragQuery.addDataAt.value)),
        op.map(([input, addOn, abbreviation]) => generatePayloadTimelineChartLines('line', input, this.addData.bind(this),`Payload (${abbreviation})`,true,true,true,true))
    )

    generateTablesData(input: fragQuery.timeline.sieveSizesAtPercents.Response, percents: Map<number, string>): { input: fragQuery.timeline.sieveSizesAtPercents.Response, percents: Map<number, string> } {
        if (input === undefined) {
            return undefined;
        }
        let obj = transformSummary(input, percents);
        if (obj) {
            for (const entry of obj.entries()) {
                const [key, value] = entry;
                this.tableData.set(key, fragmentationChartOptions(value.rockSizes, value.rockSizeDetails, value.dxxValues))
            }
        }
        return { input, percents };
    }
}

export function transformSummary(data: fragQuery.timeline.sieveSizesAtPercents.Response | undefined, percents: Map<number, string>): Map<string, { rockSizes: Array<number>, rockSizeDetails: Array<RockSizePassingPercent>, dxxValues: Array<DXXData> }> {
    if (data) {
        const datamap: Map<string, { rockSizes: Array<number>, rockSizeDetails: Array<RockSizePassingPercent>, dxxValues: Array<DXXData> }> = new Map();
        const sievesizes: Array<number> = [];
        data.summary.psd.forEach(psd => {
            sievesizes.push(psd.size);
        });
        const dxxvalues: Array<DXXData> = [];
        let counter = 1;
        percents.forEach((percent, index) => {
            dxxvalues.push({
                label: `D${index}`,
                value: data.summary.dvalues[counter],
                index: index,
                color: percent,
                canDelete: false
            });
            counter++;
        });
        let result = { rockSizes: sievesizes, rockSizeDetails: data.summary.psd, dxxValues: dxxvalues };
        datamap.set('summary', result);
        return datamap;
    }
    return null;
}
