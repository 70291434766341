<div class="section">
    <div class="container">
      <mat-card>
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>Score Chart</ng-template>
            <ng-container>
              <div class="flex-row justify full-width feature-btn-container">
                <div class="button-group right-group flex-row end">
                  <button mat-button class="secondary-toggle-button"
                    [class.active]="timelineChartType === 'merged'"
                    (click)="toggleChart()">D Values</button>
                </div>
              </div>
              <div *ngIf="(chartLines$ | async) === undefined" class="container centered-content">
                <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
              </div>
              <div *ngIf="(fragQuery.addOnTimelineSieveSizesAtPercents$ | async) === undefined"
                class="container centered-content add-on-loader">
                <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
              </div>
              <div *ngIf="(chartLines$ | async) === null" class="container centered-content">
                <div class="container centered-content">
                  <div>
                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                  </div>
                  <div>No Data Found</div>
                </div>
              </div>
              <figure *ngIf="timelineChartType === 'default' && (scoreChartLines$ | async)"
                class="highcharts-figure full-width">
                <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                  [constructorType]="'stockChart'" [options]="scoreChartLines$ | async">
                </highcharts-chart>
              </figure>
              <figure *ngIf="timelineChartType === 'merged' && (mergedChartLines$ | async)"
                class="highcharts-figure full-width">
                <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                  [constructorType]="'stockChart'" [options]="mergedChartLines$ | async">
                </highcharts-chart>
              </figure>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </div>