import { Component, OnInit } from '@angular/core';
import { DateTime } from '@lib/date-time';
import { ActivatedRoute, Router } from '@angular/router';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { BehaviorSubject, Observable, combineLatest, firstValueFrom } from 'rxjs';
import { op, taste } from '@lib/rxjs';
import { chart } from '@lib/angular/chart'
import { DataPoint, generateTimelineChartLines, generateTimelineQualtityChartLines, generatePayloadTimelineChartLines,generateCustomTimelineChartLines } from '../chart-transforms'
import { fragQuery } from '../../../../../shared-models/frag-query';
import { User$ } from '@injectables';
import * as Highcharts from 'highcharts/highstock';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
@Component({
    selector: 'analysis',
    templateUrl: './analysis.component.html',
    styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnInit {
        
    constructor(
        public readonly fragQuery: FragQueryService,
        private readonly route: ActivatedRoute,
        private readonly router:Router,
        public readonly user$: User$,
        private dialog: DialogService,
    ) { }

    public ngOnInit(): void {
        
        this.user$.subscribe(user => {
            this.quantityUnit = user.quantityUnit;
        });
        this.fragQuery.particleSizeUnit$.subscribe(unit=>{
            this.particleSizeUnit = unit;
        })
        const fragment = JSON.parse(this.route.snapshot.fragment);
        if (fragment?.custom) {
            this.fragQuery.duration$.next('custom');
            if (Array.isArray(fragment.custom)) {
                this.fragQuery.begin$.next(
                    new DateTime(fragment.custom[0] ?? DateTime.now())
                );
                this.fragQuery.end$.next(
                    new DateTime(fragment.custom[1] ?? DateTime.now())
                );
            }
        }
        if(fragment?.device){
            this.fragQuery.devices$.subscribe(devices=>[
                devices.forEach(device=>{
                    if(device.id===fragment.device){
                        this.fragQuery.device$.next(device)
                    }
                })
            ])
        }
    }
 
    public xHighlight: DateTime.Range = null;
    public readonly durationOptions = this.fragQuery.durationOptions;
    public readonly duration$ = this.fragQuery.duration$;
    public readonly newPercents$ = this.fragQuery.newPercents$;
    public readonly devices$ = this.fragQuery.devices$;
    public readonly device$ = this.fragQuery.device$;
    public readonly shifts$ = this.fragQuery.shifts$;
    public readonly shift$ = this.fragQuery.shift$;
    public readonly begin$ = this.fragQuery.begin$;
    public readonly end$ = this.fragQuery.end$;
    public readonly currentInterval = new BehaviorSubject(null);
    public readonly chartType$ = this.fragQuery.chartType$;
    public readonly weightType$ = this.fragQuery.weightType$;
    public readonly particleSizeUnit$ = this.fragQuery.particleSizeUnit$;
    public readonly scrollBarWidth=this.fragQuery.filterIntervalLength;
    public readonly scrollTrackWidth= this.fragQuery.totalIntervalLength;
    public readonly yAxisTitle$=this.fragQuery.QuantityTitle$;
    public readonly quantityUnitSystem$ = this.fragQuery.QuantityUnitSystem$;
    public quantityChartType: 'volume'|'payload' = 'volume'; 
    public timelineChartType:'default'|'custom'='default'
    public customTimelineChartType:'range'|'size'='size'
    public quantityUnit:string;
    public particleSizeUnit:string;

    public readonly chartInputs$: Observable<fragQuery.timeline.sieveSizesAtPercents.Interval[]> = combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$
    ]).pipe(
        op.debounceTime(0),
        op.tap(([percents,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if(input[1] && input[1].intervals){
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf()-input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([percents, input]) => {
            return input ? input.intervals : null
        }),
        op.shareReplay(1)
    );

    public readonly tags$ = this.fragQuery.timelineSieveSizesAtPercents$.pipe(
        op.map((input) => input?.tags ?? []),
        op.shareReplay(1)
    );

    switchPage() {
        this.router.navigateByUrl(`/details`);
    }

    async onPlotClick (time:number){
        const input = await firstValueFrom(
            this.fragQuery.timelineSieveSizesAtPercents$
        );
    
        let nearestInterval: fragQuery.timeline.sieveSizesAtPercents.Interval | null = null;
        let minDiff: number = Infinity;
    
        for (const interval of input.intervals) {
            const intervalBegin = interval.begin.valueOf();
            const intervalEnd = interval.end.valueOf();
                
            const diffBegin = Math.abs(intervalBegin - time);
            const diffEnd = Math.abs(intervalEnd - time);
        
            if (diffBegin < minDiff) {
                minDiff = diffBegin;
                nearestInterval = interval;
            }
    
            if (diffEnd < minDiff) {
                minDiff = diffEnd;
                nearestInterval = interval;
            }
        }
        
        if (nearestInterval) {
            this.currentInterval.next(nearestInterval);
        }
    }

    public Highcharts: typeof Highcharts = Highcharts;
    public chartOptions:Highcharts.Options;

    public readonly chartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnit$,
    ]).pipe(
        op.tap(([percents,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([percents, input,input2,unit]) => {
            return generateTimelineChartLines('line',percents, input, unit, true, true, true,true, this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly chartSeries$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnit$,
    ]).pipe(
        op.tap(([percents,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([percents, input,input2,unit]) => {
            return generateTimelineChartLines('series',percents, input, unit, true, true, true, true, this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly customTimelineChartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.customSizes$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
        op.tap(([sizes,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([sizes, input,input2,unit]) => {
            return generateCustomTimelineChartLines('size','line',sizes, input, unit,true,true,true,this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly customTimelineChartSeries$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.customSizes$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
        op.tap(([sizes,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([sizes, input,input2,unit]) => {
            return generateCustomTimelineChartLines('size','series',sizes, input, unit,true,true,true,this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly customRangeTimelineChartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.customSizes$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
        op.tap(([sizes,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([sizes, input,input2,unit]) => {
            return generateCustomTimelineChartLines('range','line',sizes, input, unit,true,true,true,this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly customRangeTimelineChartSeries$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.customSizes$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnitSystem$,
    ]).pipe(
        op.tap(([sizes,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([sizes, input,input2,unit]) => {
            return generateCustomTimelineChartLines('range','series',sizes, input, unit,true,true,true,this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    public readonly quantityChartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$,
        this.yAxisTitle$
    ]).pipe(
        op.tap(([input,addOn,title])=>combineQunatityInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.map(([input,addOn,title])=>generateTimelineQualtityChartLines('line',input,this.addData.bind(this),title,true,true,true,true))
    )

    public readonly quantityChartSeries$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$,
        this.yAxisTitle$
    ]).pipe(
        op.tap(([input,addOn,title])=>combineQunatityInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.map(([input,addOn,title])=>generateTimelineQualtityChartLines('series',input,this.addData.bind(this),title,true,true,true,true))
    )

    public readonly payloadChartSeries$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$,
        this.fragQuery.payloadUnitAbbreviation$
    ]).pipe(
        op.tap(([input,addOn,abbreviation])=>combineQunatityInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.map(([input,addOn,abbreviation])=>generatePayloadTimelineChartLines('series',input,this.addData.bind(this),`Payload (${abbreviation})`,true,true,true,true))
    )
    
    public readonly payloadChartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$,
        this.fragQuery.payloadUnitAbbreviation$
    ]).pipe(
        op.tap(([input,addOn,abbreviation])=>combineQunatityInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.map(([input,addOn,abbreviation])=>generatePayloadTimelineChartLines('line',input,this.addData.bind(this),`Payload (${abbreviation})`,true,true,true,true))
    )
  
    addData(type:string){
        this.fragQuery.addDataAt.next(type);
    }
     
    openCustomSizerangeDialog(){
        this.dialog.openCustomSizeSettings();
    }
    
    isSmallScreen(): boolean {
        return window.innerWidth <= 780;
    }

    public onPlotMouseMove(evt: chart.line.PlotEvent) {
        if (evt.series) {
            const input = taste(this.fragQuery.timelineSieveSizesAtPercents$);
            if (input) {
                const dataPoint = <DataPoint>(
                    evt.series.data[Math.floor(evt.dataIndex + 0.5)]
                );
                if (dataPoint) {
                    const interval = dataPoint[2];
                    if (interval) {
                        this.xHighlight = new DateTime.Range(interval.begin, interval.end);
                    }
                }
            }
        }
    }
}

export const combineQunatityInput=(input:fragQuery.timeline.quantity.Response,addon:fragQuery.timeline.quantity.Response,addTo:string):fragQuery.timeline.quantity.Response=>{
    if(addTo && addon){
        if(addTo==='begin'){
            input.begin=addon.begin;
            input.intervals.unshift(...addon.intervals)
        }else{
            input.end=addon.end;
            input.intervals.push(...addon.intervals)
        }
        return input
    }
    return input
}

export const combineInput=(input:fragQuery.timeline.sieveSizesAtPercents.Response,addon:fragQuery.timeline.sieveSizesAtPercents.Response,addTo:string):fragQuery.timeline.sieveSizesAtPercents.Response=>{
    if(addTo && addon && input.begin !==addon.begin && input.end !==addon.end){

        if(addTo==='begin'){
            input.begin=addon.begin;
            input.intervals.unshift(...addon.intervals)
        }else{
            input.end=addon.end;
            input.intervals.push(...addon.intervals)
        }
        const filteredInterval=input.intervals.filter((interval, index, intervals) => {
            if (index === 0) {
                return true;
            }
            const currentBeginTime = new Date(interval.begin).getTime();
            const previousBeginTime = new Date(intervals[index - 1].begin).getTime();

            return currentBeginTime !== previousBeginTime;
        });

        input.intervals=filteredInterval;
        return input
    }
    return input
}

export const getSummaryData=(input:fragQuery.timeline.sieveSizesAtPercents.Response):fragQuery.timeline.sieveSizesAtPercents.Interval=>{
    return input?{
        begin:input.begin,
        end:input.end,
        imageUrl:'',
        psd:input.summary.psd,
        customSizePercents:Object.values(input.summary.dvalues),
        data:Object.values(input.summary.dvalues),
        slabs:0,
        thresholdCount:0,
    }:undefined
}