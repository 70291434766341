<div class="section start">
    <div class="container">
        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>
                    Live Tag Settings
                </ng-template>
                <div class="body-content"><live-tag-settings></live-tag-settings></div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Shifts
                </ng-template>
                <div class="body-content"><shifts></shifts></div>
            </mat-tab>
            <mat-tab *ngIf="isSuperAdmin">
                <ng-template mat-tab-label>
                    Client
                </ng-template>
                <div class="body-content"><app-clients></app-clients></div>
            </mat-tab>
            <mat-tab *ngIf="isSuperAdmin || isUserClientAdmin">
                <ng-template mat-tab-label>
                    Devices
                </ng-template>
                <div class="body-content"><app-devices></app-devices></div>
            </mat-tab>
            <mat-tab *ngIf="isSuperAdmin || isUserClientAdmin">
                <ng-template mat-tab-label>
                    Users
                </ng-template>
                <div class="body-content"><app-users></app-users></div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Target Interval Settings (Device)
                </ng-template>
                <div class="body-content"><target-interval-settings></target-interval-settings></div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
