<div mat-dialog-header class="title">
	Client
</div>
<div mat-dialog-content>
	<div class="flex-column">
		<mat-form-field>
			<mat-label>Name</mat-label>
			<input matInput type="text" [(ngModel)]="name"/>
		</mat-form-field>
		<mat-checkbox [(ngModel)]="enabled">Enabled</mat-checkbox>
	</div>
</div>
<div mat-dialog-actions>
	<button mat-button (click)="cancel()">Cancel</button>
	<button mat-flat-button color="primary" [disabled]="!valid()" (click)="save()">Save</button>
</div>
