import {Injectable,Provider} from "@angular/core";
import {WebClient} from "@injectables";
import * as Sentry from '@sentry/angular-ivy';
import {BehaviorSubject,Observable,firstValueFrom,of,op} from "@lib/rxjs";
import {storageLocal} from "@lib/storage-local";
import {User,UserLoginBody} from "@models";
import { environment } from "@environment";

@Injectable({
	providedIn: "root",
})
export class User$ extends Observable<User>{
	public constructor(
		loginService:LoginService,
	){
		super(subscriber=>loginService.user$.subscribe(subscriber));
	}
}

@Injectable({
	providedIn: "root",
})
export class LoginService {
	constructor(
		private readonly webClient:WebClient,
	){
		this.init();
	}

	public readonly user$=new BehaviorSubject<User>(undefined);

	private readonly me$=this.webClient.serverReachable$
		.pipe(
			op.filter(v=>v),
			op.takeUntil(this.user$.pipe(op.filter(v=>!!v))),
			op.switchMap(()=>{
				const auth=storageLocal.get('auth');
				if(!(auth))
					return of(WebClient.Response.error(['not sent']));
				return this.webClient.rest$({
					route: 'user/me',
					method: 'post',
					body: auth,
				});
			}),
			op.map(r=>r.to(User)),
			op.shareReplay(1));
		
	private init(){
		this.me$
			.subscribe(user=>{
				if(environment.sentryUrl){
					if(user)
						Sentry.setUser({id:`${user.id}`,email:user.email});
					else
						Sentry.setUser(null);
				}

				this.user$.next(user);
			});
	}

	public async login(
		email:string,
		password:string,
	){
		const body:UserLoginBody={
			email,
			password,
		};

		const response=await this.webClient.rest({
			route: 'user/login',
			method: 'post',
			body,
		});

		const user=response.to(User);
		if(user){
			storageLocal.set('auth',{
				email: user.email,
				authToken: user.authToken,
			});
			this.user$.next(user);
			return user;
		}
		return response;
	}

	public async logout() {
		storageLocal.clear();
		window.location.href='./login';
	}

	public isLoggedIn(){
		return firstValueFrom(this.user$).then(v=>!!v);
	}

	public async generateAPIToKen(tokenName:string):Promise<string>{
		const auth=storageLocal.get('auth');
		const response=await this.webClient.rest({
			route: 'user/apitoken',
			method: 'post',
			body:{
				tokenName,
				...auth
			},
		});
		const newToken=response.value;
		if(response){
			return newToken;
		}
		return undefined;
	}

	public async deleteAPIToKen():Promise<boolean>{
		const auth=storageLocal.get('auth');
		const response=await this.webClient.rest({
			route: 'user/apitoken',
			method: 'delete',
			body:auth
		});
		if(response.value){
			return true;
		}
		return false;
	}

	public isUserSuperAdmin(): boolean {
		const permissionNeededForAdmin = 2;
		const user = this.user$.value;
		return user?.permissions === permissionNeededForAdmin;
	}

	public isUserClientAdmin(): boolean {
		const permissionNeededForClientAdmin = 1;
		const user = this.user$.value;
		return user?.permissions === permissionNeededForClientAdmin;
	}
}

export const userProvider:Provider={
	//gives us direct access to user if you don't need the whole service
	provide: User,
	useFactory: (service:LoginService) => service.user$.getValue(),
	deps: [LoginService]
};
