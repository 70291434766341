import { chart } from "@lib/angular/chart";
import { fragQuery, TimelineCustomSizes } from "../../../../shared-models/frag-query";
import { DateTime } from "@lib/date-time";
import { getChartLineSeperationThreshold } from "@app/old-ui/dashboard/shared";
import { measurement } from "@lib/measurement";
import { interval } from "rxjs";
import { ExtendedTypeMeta } from "@lib/measurement/types";
import { MeasurementTypeToUnit } from "@lib/angular/measurement/measurement-type-to-unit";
import { ResponsiveCallbackFunction } from "highcharts";

export const generateChartSummaryLines=( percents: Map<number, string>, input: fragQuery.timeline.sieveSizesAtPercents.Response ) => {
    const lines: chart.line.Series[] = [];
    if (input.summary) {
        for (const [percent, color] of percents) {
            let value = input.summary.dvalues[percent];
            if (value !== undefined) {
                lines.push({
                    type: 'line',
                    name: null,
                    data: [
                        [input.begin, value],
                        [input.end, value],
                    ],
                    color,
                    width: 1,
                    dash: [4],
                    includeInRange: [true, true],
                });
            }
        }
    }
    return lines;
}

export const generateToleranceSpecLines=(input: fragQuery.timeline.sieveSizesAtPercents.Response) => {
    return input.tags.map(
        (tag): chart.line.Series => ({
            type: 'line',
            name: 'Tolerance Spec',
            data: [
                [new DateTime(tag.begin * 1000), tag.toleranceSpecSize],
                [
                    tag.end ? new DateTime(tag.end * 1000) : input.end,
                    tag.toleranceSpecSize,
                ],
            ],
            color: 'white',
            width: 1,
            dash: [4],
            includeInRange: [false, false],
        })
    );
}

export type DataPoint = [
    DateTime,
    number,
    fragQuery.timeline.sieveSizesAtPercents.Interval
];

export const generateChartLines=(percents: Map<number, string>, input: fragQuery.timeline.sieveSizesAtPercents.Response):chart.line.Series[] => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return [];
    }
    percents = new Map(percents);
    const series = generateChartSummaryLines(percents, input);

    const seperationThreshold = getChartLineSeperationThreshold(input.intervals);

    series.push(
        ...input.percents
            .sort((a, b) => a - b)
            .map((percent, i): chart.line.Series => {
                if (!percents.has(percent)) return null;
                const data: DataPoint[] = [];
                let prevInterval: fragQuery.timeline.sieveSizesAtPercents.Interval;
                for (const interval of input.intervals) {
                    const y = (interval.data[i] ?? 0);
                    if (!prevInterval) {
                        data.push([interval.begin, y, interval]);
                    } else if (
                        prevInterval.end.getTime() - interval.begin.getTime() >=
                        seperationThreshold
                    ) {
                        data.push([prevInterval.end, 0, interval]);
                        data.push([interval.begin, 0, interval]);
                    }
                    const x = interval.begin.lerp(interval.end, 0.5);
                    data.push([x, y, interval]);
                    prevInterval = interval;
                }

                return {
                    type: 'line',
                    name: 'D' + percent,
                    data,
                    color: percents.get(percent),
                    fill: true,
                    includeInRange: [false, true],
                };
            })
            .filter((v) => !!v)
            .reverse()
    );
    series.push(...generateToleranceSpecLines(input));

    return series;
}

export const generateChartSeries=(percents: Map<number, string>,input: fragQuery.timeline.sieveSizesAtPercents.Response):chart.line.Series[] => {
    
    if (input === undefined) {
        return undefined;
    }

    if (!(input?.intervals?.length > 0)) {
        return [];
    }

    percents = new Map(percents);
    const series = generateChartSummaryLines(percents, input);

    series.push(
        ...input.percents
            .sort((a, b) => a - b)
            .map((percent, i): chart.line.Series => {
                if (!percents.has(percent)) return null;
                const data = input.intervals.map(
                    (interval) =>
                        <DataPoint>[
                            interval.begin,
                            (interval.data[i] ?? 0),
                            interval,
                        ]
                );
                return {
                    type: 'occurances',
                    name: 'D' + percent,
                    data,
                    color: percents.get(percent),
                    includeInRange: [false, true],
                };
            })
            .filter((v) => !!v)
            .reverse()
    );
    series.push(...generateToleranceSpecLines(input));
    return series;
}

export const generateQuantityChartLines = (input: fragQuery.timeline.quantity.Response) => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return [];
    }

    const separationThreshold = getChartLineSeperationThreshold(input.intervals);
    const data: [DateTime, number][] = [];
    for (const [a, b] of input.intervals.pairs()) {
        const valueA = a.data;
        const valueB = b.data;

        data.push([a.end, valueA]);
        if (Math.abs(a.end.getTime() - b.begin.getTime()) >= separationThreshold) {
            data.push([a.end, 0]);
            data.push([b.begin, 0]);
            data.push([b.begin, valueB]);
        }
    }

    const last = input.intervals.at(-1);
    const lastValue = last.data;
    data.push([last.end, lastValue]);

    const lines: chart.line.Series[] = [];
    lines.push({
        type: 'line',
        name: 'Quantity',
        color: 'lightblue',
        fill: true,
        data,
    });
    if (lines[0].data.length === 1) {
        lines[0].data.unshift([input.intervals[0].begin, lines[0].data[0][1]]);
    }
    return lines;
}

export const generatePayloadChartineForPdf = (input: fragQuery.timeline.quantity.Response) => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return [];
    }

    const separationThreshold = getChartLineSeperationThreshold(input.intervals);
    const data: [DateTime, number][] = [];
    for (const [a, b] of input.intervals.pairs()) {
        const valueA = a.payload;
        const valueB = b.payload;

        data.push([a.end, valueA]);
        if (Math.abs(a.end.getTime() - b.begin.getTime()) >= separationThreshold) {
            data.push([a.end, 0]);
            data.push([b.begin, 0]);
            data.push([b.begin, valueB]);
        }
    }

    const last = input.intervals.at(-1);
    const lastValue = last.payload;
    data.push([last.end, lastValue]);

    const lines: chart.line.Series[] = [];
    lines.push({
        type: 'line',
        name: 'Quantity',
        color: 'lightgreen',
        fill: true,
        data,
    });
    if (lines[0].data.length === 1) {
        lines[0].data.unshift([input.intervals[0].begin, lines[0].data[0][1]]);
    }
    return lines;
}

export const generateQuantityChartSeries = (input: fragQuery.timeline.quantity.Response) => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return [];
    }

    const bars: chart.line.Series[] = [];
    bars.push({
        type: 'occurances',
        name: 'Quantity',
        color: 'lightblue',
        data: input.intervals.map(interval => [interval.begin, interval.data]),
    });
    return bars;
}

export type TimeLineDataPoint = [
    number,
    number
];

export const generateTimelineChartSummaryLines=( percents: Map<number, string>, input: fragQuery.timeline.sieveSizesAtPercents.Response ) => {
    const lines: Array<Highcharts.SeriesOptionsType> =[];
    if (input.summary) {
        for (const [percent, color] of percents) {
            let value = input.summary.dvalues[percent];
            if (value !== undefined) {
                lines.push({
                    type: 'line',
                    name: null,
                    data: [
                        [input.begin.valueOf(), value],
                        [input.end.valueOf(), value],
                    ],
                    color,
                    lineWidth:2,
                    dashStyle:'ShortDash',
                    enableMouseTracking:false
                });
            }
        }
    }
    return lines;
}

export const generateTimelineToleranceSpecLines=(input: fragQuery.timeline.sieveSizesAtPercents.Response) => {
    return input.tags.map(
        (tag): Highcharts.SeriesOptionsType => ({
            type: 'line',
            name: 'Tolerance Spec',
            data: [
                [(tag.begin<input.begin.valueOf()) ? input.begin.valueOf() : tag.begin*1000, tag.toleranceSpecSize],
                [
                    tag.end ? tag.end*1000 : input.end.valueOf(),
                    tag.toleranceSpecSize,
                ],
            ],
            color: 'white',
            lineWidth: 2,
            dashStyle:'Dash'
        })
    );
}

export const generateTimelineChartLines=(chartType:"series"|"line",percents:Map<number, string>,input:fragQuery.timeline.sieveSizesAtPercents.Response,particleSizeUnit:string,zoomingEnable:boolean,navigatorEnable:boolean,tooltipEnable:boolean,scrollbarEnable:boolean,addData?: (type: string) => void,plotClick?: (time:number) => void):Highcharts.Options => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return null;
    }
    percents = new Map(percents);
    
    const series: Array<Highcharts.SeriesOptionsType> =[];

    const seperationThreshold = getChartLineSeperationThreshold(input.intervals);
    series.push(...generateTimelineChartSummaryLines(percents, input));

    series.push(
        ...input.percents
            .sort((a, b) => a - b)
            .map((percent, i): Highcharts.SeriesOptionsType => {
                if (!percents.has(percent)) return null;
                if(chartType==='line'){
                    const data: TimeLineDataPoint[] = [];
                    let prevInterval: fragQuery.timeline.sieveSizesAtPercents.Interval;
                    for (const interval of input.intervals) {
                        const y = (interval.data[i] ?? 0);
                        if (!prevInterval) {
                            data.push([interval.begin.valueOf(), y]);
                        } else if (
                            prevInterval.end.getTime() - interval.begin.getTime() >=
                            seperationThreshold
                        ) {
                            data.push([prevInterval.end.valueOf(), 0]);
                            data.push([interval.begin.valueOf(), 0]);
                        }
                        const x = interval.begin.lerp(interval.end, 0.5).valueOf();
                        data.push([x, y]);
                        prevInterval = interval;
                    }

                    return {
                        type: 'spline',
                        name: 'D' + percent,
                        data,
                        color: percents.get(percent),
                        lineWidth:4,
                        point: {
                            events: {
                                click: function (event) {
                                    if(plotClick){
                                        const clickedXValue = this.series.xAxis.toValue(event.chartX);
                                        plotClick(clickedXValue)
                                    }
                                }
                            }
                        }
                    };
                } else{
                    const data = input.intervals.map(
                        (interval) =>
                            <TimeLineDataPoint>[
                                interval.begin.valueOf(),
                                (i>1 ? interval.data[i]-interval.data[i-1] : interval.data[i]) ?? 0
                            ]
                            );
                            return {
                                type: 'column',
                                name: 'D' + percent,
                                data,
                                grouping:false,
                                dataGrouping: {
                                    enabled: false
                                },
                                color: percents.get(percent),
                                point: {
                                    events: {
                                        click: function (event) {
                                            if(plotClick){
                                                const clickedXValue = this.series.xAxis.toValue(event.chartX);
                                                plotClick(clickedXValue)
                                            }
                                        }
                                    }
                                }
                            };
                }
            })
            .filter((v) => !!v)
            .reverse()
    );

    series.push(...generateTimelineToleranceSpecLines(input));

    let options:Highcharts.Options={
        rangeSelector: {
            enabled:false
        },

		credits: {
			enabled: false
		},

        chart:{
            backgroundColor:'transparent',
            zooming: {
                mouseWheel: {
                  enabled: zoomingEnable
                }
              },
            events:{
                click: function(e:any) {
                    plotClick(e.xAxis[0].value)
                }
            }
        },

        exporting: {
            enabled: false
        },
        navigator: {
            enabled: navigatorEnable
        },
        plotOptions: {
            series: {
                showInNavigator: true,
                dataLabels:[{
                    color:'#777'
                }]
            },
            column:{
                showInNavigator:true,
                stacking:'normal',
                grouping:false,
                dataGrouping: {
                    enabled: false
                }
            }
        },
        responsive: {
            rules: [{
                chartOptions: {
                    plotOptions: {
                        series: {
                            showInNavigator: true,
                            dataLabels: [{
                                color: '#777'
                            }]
                        },
                        column: {
                            showInNavigator: true,
                            stacking: 'normal',
                            pointWidth: 1,
                            pointPadding: 1,
                            grouping: false,
                            dataGrouping: {
                                enabled: false
                            },
                        }
                    },
                },
                condition: {
                    callback: function () {
                        // Get the visible range from the xAxis
                        const xAxis = this.xAxis[0];
                        const min = xAxis.min;
                        const max = xAxis.max;
                        // Calculate visible points within the current view for all series
                        const visiblePoints = this.series.reduce((sum, series) => {
                            if (series.visible && series.data) {  // Ensure series is visible and has data
                                return sum + series.data.filter(point =>
                                    point.x >= min && point.x <= max
                                ).length;
                            }
                            return sum;
                        }, 0);
                        // Return true if visible points exceed 50
                        return visiblePoints > 1000;
                    }
                }
            }],
        },
        tooltip: {
            shared: false,
            split: true,
            useHTML: true,
            enabled: tooltipEnable,
            pointFormatter: function () {
                const imageUrl = input.intervals[this.index - 1].imageUrl;
                const img = `<img src="${imageUrl}" onerror="this.src='../../assets/image_not_found.jpg'" height="200" width="300"/>`;
                return img;
            }
        },

        time:{
            timezoneOffset:new Date().getTimezoneOffset()
        },

        scrollbar: {
            liveRedraw: false,
            showFull:false,
            barBackgroundColor:'#5d7af6',
            enabled: scrollbarEnable
        },

        xAxis: {
            title: { 
                text: 'Time',
                style:{
                    fontSize:'1em',
                    color:'#777'
                },
            },
            labels:{
                style:{
                    color:'#777'
                }
            },
            // to prevent timeline axis from auto shrinking when data is not present for a timestamp
            breaks:[{
                breakSize:1000
            }],
            tickInterval: 3600 * 1000,
            events: {
                afterSetExtremes: function(event) {
                    if(addData){
                        if(event.trigger && event.userMin===event.dataMin){
                            addData('begin')
                        }
                        else if(event.trigger && event.dataMax===event.userMax){
                            addData('end')
                        }
                    }
                }
            }
        },

        yAxis:{
            title: { 
                text:`Particle Size (${particleSizeUnit})`,
                style:{
                    fontSize:'1em',
                    color:'#777'
                }
            },
            labels:{
                style:{
                    color:'#777'
                }
            },
            tickAmount:8,
            opposite:false
        },
        series: series
    };

    return options;
}


export const generateCustomTimelineChartLines = (
    type:'range'|'size',
    chartType: "series" | "line",
    sizes: Map<number, TimelineCustomSizes>,
    input: fragQuery.timeline.sieveSizesAtPercents.Response,
    particleSizeUnitSystem: string,
    zoomingEnable: boolean,
    navigatorEnable: boolean,
    scrollbarEnable:boolean,
    addData?: (type: string) => void,
    plotClick?: (time: number) => void
): Highcharts.Options => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return null;
    }

    let inputSizes: number[] = Array.from(sizes.keys());
    if(type == 'range'){
        inputSizes.push(1000) // a random max size just to increase the iterator
    }

    const series: Array<Highcharts.SeriesOptionsType> = [];

    const unitSystem = particleSizeUnitSystem as keyof(ExtendedTypeMeta);
    const measurementTypeToUnit = new MeasurementTypeToUnit;
    const unit = measurementTypeToUnit.getUnitsForSystem(unitSystem).particleSize;
    let prevSize = 0;
    series.push(
        ...inputSizes
            .map((size, i): Highcharts.SeriesOptionsType => {
                let upperSizeLabel;
                let lowerSizeLabel;
                const color = sizes.get(size)?.color ?? 'red';
                const label = sizes.get(size)?.label ?? '';
                if(i < inputSizes.length - 1){
                    upperSizeLabel = sizes.get(size)?.label;
                    lowerSizeLabel = sizes.get(inputSizes[i -1])?.label;
                }else{
                    lowerSizeLabel = sizes.get(prevSize)?.label
                }

                let lineName;
                prevSize = size;
                if(type == 'size'){
                    lineName = (label && label!=='Custom') ? '< '+ measurement.convert(size,'meter', unit).toFixed(2) + `${measurement.abbreviation(unit)}` + ` (${label})` : '< '+ measurement.convert(size,'meter', unit).toFixed(2) + `${measurement.abbreviation(unit)}`
                }else{
                    let upperSize;
 
                    let lowerSize;
                    if(i == 0 ){
                        lowerSize = '<'
                        upperSize = (upperSizeLabel != 'Custom') ? upperSizeLabel :  measurement.convert((i== inputSizes.length-1)? inputSizes[i-1] :inputSizes[i], 'meter', unit).toFixed(2) + `${measurement.abbreviation(unit)}`
                    }else if(i == inputSizes.length-1){
                        lowerSize = '>'
                        upperSize =  (lowerSizeLabel != 'Custom') ? lowerSizeLabel : measurement.convert((i== inputSizes.length-1)? inputSizes[i-1] :inputSizes[i], 'meter', unit).toFixed(2) + `${measurement.abbreviation(unit)}`
                    } else{
                        upperSize = (upperSizeLabel != 'Custom') ? upperSizeLabel : measurement.convert((i== inputSizes.length-1)? inputSizes[i-1] :inputSizes[i], 'meter', unit).toFixed(2) + `${measurement.abbreviation(unit)}`
                        lowerSize = (lowerSizeLabel != 'Custom') ? lowerSizeLabel : measurement.convert(inputSizes[i-1],'meter', unit).toFixed(2) + `${measurement.abbreviation(unit)}`
                    }

                    lineName = lowerSize + '-' + upperSize
                }

                if (chartType === 'line') {
                    const data: TimeLineDataPoint[] = [];
                    let prevInterval: fragQuery.timeline.sieveSizesAtPercents.Interval;

                    for (const interval of input.intervals) {
                        if(type == 'range'){
                            if(i == 0){
                                const y = (interval.customSizePercents[i] - 0 ?? 0);
                                const x = interval.begin.lerp(interval.end, 0.5).valueOf();
                                data.push([x, y]);
                            } else if( i == (inputSizes.length -1)){
                                const y2 = (100 - interval.customSizePercents[i-1] ?? 0);
                                const x2 = interval.begin.lerp(interval.end, 0.5).valueOf();
                                data.push([x2, y2]);
                            }else{
                                const y = (interval.customSizePercents[i] - interval.customSizePercents[i-1] ?? 0);
                                const x = interval.begin.lerp(interval.end, 0.5).valueOf();
                                data.push([x, y]);
                            }
                        }else{
                            const y = (interval.customSizePercents[i] ?? 0);
                            const x = interval.begin.lerp(interval.end, 0.5).valueOf();
                            data.push([x, y]);
                        }
                        prevInterval = interval;
                    }

                    return {
                        type: 'spline',
                        name: lineName,
                        data,
                        color,
                        lineWidth: 4,
                        point: {
                            events: {
                                click: function (event) {
                                    if (plotClick) {
                                        const clickedXValue = this.series.xAxis.toValue(event.chartX);
                                        plotClick(clickedXValue);
                                    }
                                },
                            },
                        },
                    };
                } else {
                    let prevVal = 0
                    const data = input.intervals.map(
                        (interval) =>{
                            let y;
                            if(type == 'range'){
                                if(i == 0){
                                    y = interval.customSizePercents[i];
                                }else if(i == inputSizes.length -1 ) {
                                    y = (interval.customSizePercents[i - 1] - interval.customSizePercents[i-2])
                                }else{
                                    y = (interval.customSizePercents[i] - interval.customSizePercents[i-1]) - (interval.customSizePercents[i-1] - interval.customSizePercents[i-2])
                                }
                                prevVal = y;
                            }else{
                                y = i == 0 ? interval.customSizePercents[i] : interval.customSizePercents[i] - interval.customSizePercents[i-1]
                            }
                            
                            return <TimeLineDataPoint>[
                                interval.begin.valueOf(),
                                y
                            ]
                        }
                    );
                    return {
                        type: 'column',
                        name:  lineName,
                        data,
                        color, // Use the color from percents Map
                        point: {
                            events: {
                                click: function (event) {
                                    if (plotClick) {
                                        const clickedXValue = this.series.xAxis.toValue(event.chartX);
                                        plotClick(clickedXValue);
                                    }
                                },
                            },
                        },
                    };
                }
            })
            .filter((v) => !!v)
            .reverse()
    );

    let options: Highcharts.Options = {
        rangeSelector: {
            enabled: false
        },

        credits: {
            enabled: false
        },

        chart: {
            backgroundColor: 'transparent',
            zooming: {
                mouseWheel: {
                    enabled: zoomingEnable
                }
            },
            events: {
                click: function (e: any) {
                    plotClick(e.xAxis[0].value);
                }
            },
        },

        exporting: {
            enabled: false
        },
        navigator: {
            enabled: navigatorEnable
        },
        plotOptions: {
            series: {
                showInNavigator: true,
                dataLabels: [{
                    color: '#777'
                }]
            },
            column: {
                showInNavigator: true,
                stacking: 'normal',
                grouping:false,
                dataGrouping:{
                    enabled:false
                }
            }
        },
        responsive: {
            rules: [{
                chartOptions: {
                    plotOptions: {
                        series: {
                            showInNavigator: true,
                            dataLabels: [{
                                color: '#777'
                            }]
                        },
                        column: {
                            showInNavigator: true,
                            stacking: 'normal',
                            pointWidth: 1,
                            pointPadding: 1,
                            grouping: false,
                            dataGrouping: {
                                enabled: false
                            },
                        }
                    },
                },
                condition: {
                    callback: function () {
                        // Get the visible range from the xAxis
                        const xAxis = this.xAxis[0];
                        const min = xAxis.min;
                        const max = xAxis.max;
                        // Calculate visible points within the current view for all series
                        const visiblePoints = this.series.reduce((sum, series) => {
                            if (series.visible && series.data) {  // Ensure series is visible and has data
                                return sum + series.data.filter(point =>
                                    point.x >= min && point.x <= max
                                ).length;
                            }
                            return sum;
                        }, 0);
                        // Return true if visible points exceed 50
                        return visiblePoints > 750;
                    }
                }
            }],
        },
        tooltip: {
            shared: true,
            split: false,
            useHTML: true,
            formatter: function () {
                const interval = input.intervals[this.point.index];
                const imageUrl = interval.imageUrl;
                const total_series = interval.customSizePercents.length;
                const chart_type = this.series.userOptions.type;
                const symbol='●';
                const dataHtml = this.points
                .map((point, index) => {
                        if (!point.series.chart.legend.group) return null;
                        const legendSymbol = `<span width='20' height='20' style="font-size:20px;color:${point.color}">${symbol}</span>`;
                        const seriesName = point.series.name;
                        const percent = point.y.toFixed(2);
                        const cummulative_percent = interval.customSizePercents[total_series -1 - point.series.index];
                        return `<span>${legendSymbol} </span><b>${seriesName}</b>: ${chart_type == 'column' ? cummulative_percent: percent}%`;
                    })
                    .reduce((acc, curr, index, array) => {
                        if (index % 2 === 0) {
                            acc.push(curr + (array[index + 1] ? ', ' + array[index + 1] : ''));
                        }
                        return acc;
                    }, [])
                    .map((line) => `<p style="margin: 0;">${line}</p>`)
                    .join('');
        
                const html = `
                <div><b>Start Time:</b> ${interval.begin.toLocaleString()}</div>
                <div style='display:flex'>
                <div>${dataHtml}</div>
                  <div style="margin-left: 10px;">
                        <img src="${imageUrl}" onerror="this.src='../../assets/image_not_found.jpg'" height="100" width="150"/>
                    </div>
                <div>
                `;
                return html;
            }
        },

        time: {
            timezoneOffset: new Date().getTimezoneOffset()
        },

        scrollbar: {
            liveRedraw: false,
            showFull: false,
            barBackgroundColor: '#5d7af6',
            enabled: scrollbarEnable
        },

        xAxis: {
            title: {
                text: 'Time',
                style: {
                    fontSize: '1em',
                    color: '#777'
                },
            },
            labels: {
                style: {
                    color: '#777'
                }
            },
            breaks: [{
                breakSize: 750
            }],
            tickInterval: 3600 * 1000,
            events: {
                afterSetExtremes: function (event) {
                    if (addData) {
                        if (event.trigger && event.userMin === event.dataMin) {
                            addData('begin');
                        } else if (event.trigger && event.dataMax === event.userMax) {
                            addData('end');
                        }
                    }
                }
            }
        },

        yAxis: {
            title: {
                text: `Percent %`,
                style: {
                    fontSize: '1em',
                    color: '#777'
                }
            },
            labels: {
                style: {
                    color: '#777'
                }
            },
            tickAmount: 8,
            opposite: false
        },
        
        legend: {
            enabled: true,
            layout: 'horizontal', 
            align: 'center', 
            verticalAlign: 'bottom',
            itemStyle: {
                fontSize: '12px',
                color: '#333',
            },
            symbolHeight: 12, 
            symbolWidth: 30, 
            symbolRadius: 20, 
        },
        series: series
    };

    return options;
};

export const generateTimelineQualtityChartLines = (
    chartType: "series" | "line",
    input: fragQuery.timeline.quantity.Response,
    addData: (type: string) => void,
    title: string,
    zoomingEnable: boolean,
    navigatorEnable: boolean,
    scrollbarEnable: boolean,
    tooltipEnable: boolean,
): Highcharts.Options => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return null;
    }

    const separationThreshold = getChartLineSeperationThreshold(input.intervals);
    const data: [number, number][] = [];
    for (const [a, b] of input.intervals.pairs()) {
        const valueA = a.data;
        const valueB = b.data;

        data.push([a.end.valueOf(), valueA]);
        if (Math.abs(a.end.getTime() - b.begin.getTime()) >= separationThreshold) {
            data.push([a.end.valueOf(), 0]);
            data.push([b.begin.valueOf(), 0]);
            data.push([b.begin.valueOf(), valueB]);
        }
    }

    const last = input.intervals.at(-1);
    const lastValue = last.data;
    data.push([last.end.valueOf(), lastValue]);

    if (data.length === 1) {
        data.unshift([input.intervals[0].begin.valueOf(), data[0][1]]);
    }

    const series: Array<Highcharts.SeriesOptionsType> = [];
    chartType === 'line'
        ? series.push({
              type: 'spline',
              name: 'Quantity',
              color: 'lightblue',
              data,
          })
        : series.push({
              type: 'column',
              name: 'Quantity',
              color: 'lightblue',
              data,
          });

    let options: Highcharts.Options = {
        rangeSelector: {
            enabled: false,
        },

        credits: {
            enabled: false,
        },

        chart: {
            backgroundColor: 'transparent',
            zooming: {
                mouseWheel: {
                    enabled: zoomingEnable,
                },
            },
        },

        exporting: {
            enabled: false,
        },

        navigator: {
            enabled: navigatorEnable,
        },

        plotOptions: {
            series: {
                showInNavigator: true,
            },
        },

        time: {
            timezoneOffset: new Date().getTimezoneOffset(),
        },

        scrollbar: {
            liveRedraw: false,
            showFull: false,
            barBackgroundColor: '#5d7af6',
            enabled: scrollbarEnable,
        },

        tooltip:{          
            enabled: tooltipEnable,            
        },

        xAxis: {
            title: {
                text: 'Time',
                style: {
                    fontSize: '1em',
                    color: '#777',
                },
            },
            labels: {
                style: {
                    color: '#777',
                },
            },
            breaks: [
                {
                    breakSize: 1000,
                },
            ],
            events: {
                afterSetExtremes: function (event) {
                    if (event.trigger && event.userMin === event.dataMin) {
                        addData('begin');
                    } else if (event.trigger && event.dataMax === event.userMax) {
                        addData('end');
                    }
                },
            },
        },

        yAxis: {
            title: {
                text: `${title}`,
                style: {
                    fontSize: '1em',
                    color: '#777',
                },
            },
            labels: {
                style: {
                    color: '#777',
                },
            },
            tickAmount: 8,
            opposite: false,
        },


        legend: {
            enabled: true,
            layout: 'horizontal', 
            align: 'center', 
            verticalAlign: 'bottom',
            itemStyle: {
                fontSize: '12px',
                color: '#333',
            },
            symbolHeight: 12, 
            symbolWidth: 30, 
            symbolRadius: 20, 
        },

        series: series,
    };

    return options;
};

export const generatePayloadTimelineChartLines = (
    chartType: "series" | "line",
    input: fragQuery.timeline.quantity.Response,
    addData: (type: string) => void,
    title: string,
    zoomingEnable: boolean,
    navigatorEnable: boolean,
    scrollbarEnable: boolean,
    tooltipEnable: boolean,
): Highcharts.Options => {
    if (input === undefined) {
        return undefined;
    }
    if (!(input?.intervals?.length > 0)) {
        return null;
    }

    const separationThreshold = getChartLineSeperationThreshold(input.intervals);
    const data: [number, number][] = [];
    for (const [a, b] of input.intervals.pairs()) {
        const valueA = a.payload;
        const valueB = b.payload;

        data.push([a.end.valueOf(), valueA]);
        if (Math.abs(a.end.getTime() - b.begin.getTime()) >= separationThreshold) {
            data.push([a.end.valueOf(), 0]);
            data.push([b.begin.valueOf(), 0]);
            data.push([b.begin.valueOf(), valueB]);
        }
    }

    const last = input.intervals.at(-1);
    const lastValue = last.payload;
    data.push([last.end.valueOf(), lastValue]);

    if (data.length === 1) {
        data.unshift([input.intervals[0].begin.valueOf(), data[0][1]]);
    }

    const series: Array<Highcharts.SeriesOptionsType> = [];
    chartType === 'line'
        ? series.push({
              type: 'spline',
              name: 'Payload',
              color: 'lightgreen',
              data,
          })
        : series.push({
              type: 'column',
              name: 'Payload',
              color: 'lightgreen',
              data,
          });

    let options: Highcharts.Options = {
        rangeSelector: {
            enabled: false,
        },

        credits: {
            enabled: false,
        },

        chart: {
            backgroundColor: 'transparent',
            zooming: {
                mouseWheel: {
                    enabled: zoomingEnable,
                },
            },
        },

        exporting: {
            enabled: false,
        },

        navigator: {
            enabled: navigatorEnable,
        },

        plotOptions: {
            series: {
                showInNavigator: navigatorEnable,
            },
        },

        time: {
            timezoneOffset: new Date().getTimezoneOffset(),
        },

        scrollbar: {
            liveRedraw: false,
            showFull: false,
            barBackgroundColor: '#5d7af6',
            enabled: scrollbarEnable,
        },

        tooltip:{          
            enabled: tooltipEnable,            
        },

        xAxis: {
            title: {
                text: 'Time',
                style: {
                    fontSize: '1em',
                    color: '#777',
                },
            },
            labels: {
                style: {
                    color: '#777',
                },
            },
            breaks: [
                {
                    breakSize: 1000,
                },
            ],
            events: {
                afterSetExtremes: function (event) {
                    if (event.trigger && event.userMin === event.dataMin) {
                        addData('begin');
                    } else if (event.trigger && event.dataMax === event.userMax) {
                        addData('end');
                    }
                },
            },
        },

        yAxis: {
            title: {
                text: `${title}`,
                style: {
                    fontSize: '1em',
                    color: '#777',
                },
            },
            labels: {
                style: {
                    color: '#777',
                },
            },
            tickAmount: 8,
            opposite: false,
        },

    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        fontSize: '12px',
        color: '#333',
      },
      symbolHeight: 12,
      symbolWidth: 30,
      symbolRadius: 20,
    },

    series: series,
  };

  return options;
};

export const generateScoreTimelineChartLines = (
  chartType: 'series' | 'line',
  input: fragQuery.timeline.quantity.Response,
  addData: (type: string) => void,
  title: string,
  zoomingEnable: boolean,
  navigatorEnable: boolean,
  scrollbarEnable: boolean,
  tooltipEnable: boolean
): Highcharts.Options => {
  if (input === undefined) {
    return undefined;
  }
  if (!(input?.intervals?.length > 0)) {
    return null;
  }

  const separationThreshold = getChartLineSeperationThreshold(input.intervals);
  const scoreData: [number, number][] = [];
  const iqaScoreData :[number,number][] = [];
  
  let minValue = Number.MAX_VALUE;
  let maxValue = Number.MIN_VALUE;
  
  for (const [a, b] of input.intervals.pairs()) {
    const valueAScore = Number((a.score * 100).toFixed(2));
    const valueBScore = Number((b.score * 100).toFixed(2));
    const valueAIQAScore = Number((a.iqaScore * 100).toFixed(2));
    const valueBIQAScore = Number((b.iqaScore * 100).toFixed(2));

    minValue = Math.min(minValue, valueAScore, valueBScore, valueAIQAScore, valueAIQAScore);
    maxValue = Math.max(maxValue, valueAScore, valueBScore, valueAIQAScore, valueBIQAScore);

    scoreData.push([a.end.valueOf(), valueAScore]);
    iqaScoreData.push([a.end.valueOf(), valueAIQAScore]);
    if (Math.abs(a.end.getTime() - b.begin.getTime()) >= separationThreshold) {
      scoreData.push([a.end.valueOf(), 0]);
      scoreData.push([b.begin.valueOf(), 0]);
      iqaScoreData.push([a.end.valueOf(),0]);
      iqaScoreData.push([b.begin.valueOf(),0]);
      scoreData.push([b.begin.valueOf(), valueBScore]);
      iqaScoreData.push([b.begin.valueOf(), valueBIQAScore]);
    }
  }

  const last = input.intervals.at(-1);
  const lastScoreValue = Number((last.score * 100).toFixed(2));
  const lastiqaScoreValue = Number((last.iqaScore * 100).toFixed(2));

  minValue = Math.min(minValue, lastiqaScoreValue,lastScoreValue);
  maxValue = Math.max(maxValue, lastiqaScoreValue,lastScoreValue);

  scoreData.push([last.end.valueOf(), lastScoreValue]);
  iqaScoreData.push([last.end.valueOf(), lastiqaScoreValue]);

  if (scoreData.length === 1) {
    scoreData.unshift([input.intervals[0].begin.valueOf(), scoreData[0][1]]);
  }

  const series: Array<Highcharts.SeriesOptionsType> = [];
  chartType === 'line'
    ? series.push({
        type: 'spline',
        name: 'AI Score',
        color: 'red',
        data:scoreData,
      },{
        type: 'spline',
        name: 'Image Quality',
        color: 'blue',
        data: iqaScoreData
      })
    : series.push({
        type: 'column',
        name: 'AI Score',
        color: 'red',
        data:scoreData,
      },{
        type:'column',
        name: 'Image Quality',
        color: 'blue',
        data: iqaScoreData
      });

  let options: Highcharts.Options = {
    rangeSelector: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    chart: {
      backgroundColor: 'transparent',
      zooming: {
        mouseWheel: {
          enabled: zoomingEnable,
        },
      },
    },

    exporting: {
      enabled: false,
    },

    navigator: {
      enabled: navigatorEnable,
    },

    plotOptions: {
      series: {
        showInNavigator: navigatorEnable,
      },
    },

    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },

    scrollbar: {
      liveRedraw: false,
      showFull: false,
      barBackgroundColor: '#5d7af6',
      enabled: scrollbarEnable,
    },

    tooltip: {
      enabled: tooltipEnable,
    },

    xAxis: {
      title: {
        text: 'Time',
        style: {
          fontSize: '1em',
          color: '#777',
        },
      },
      labels: {
        style: {
          color: '#777',
        },
      },
      breaks: [
        {
          breakSize: 1000,
        },
      ],
      events: {
        afterSetExtremes: function (event) {
          if (event.trigger && event.userMin === event.dataMin) {
            addData('begin');
          } else if (event.trigger && event.dataMax === event.userMax) {
            addData('end');
          }
        },
      },
    },

    yAxis: {
      title: {
        text: `${title}`,
        style: {
          fontSize: '1em',
          color: '#777',
        },
      },
      labels: {
        style: {
          color: '#777',
        },
      },
      tickAmount: 8,
      opposite: false,
      min: minValue - 2,
      max: maxValue + 2,
    },

    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        fontSize: '12px',
        color: '#333',
      },
      symbolHeight: 12,
      symbolWidth: 30,
      symbolRadius: 20,
    },

    series: series,
  };

  return options;
};

export const generateMergedTimelineChartLines = (
  percents: Map<number, string>,
  input: fragQuery.timeline.sieveSizesAtPercents.Response,
  inputScore: fragQuery.timeline.quantity.Response,
  particleSizeUnit: string,
  addData: (type: string) => void,
  plotClick: (time: number) => void
): Highcharts.Options => {
  if (
    !input ||
    !inputScore ||
    !input.intervals?.length ||
    !inputScore.intervals?.length
  ) {
    return null;
  }

  const scoreData: [number, number][] = [];
  const iqaScoreData: [number, number][] = [];
  const particleSeries: Array<Highcharts.SeriesOptionsType> = [];
  const scoreSeries: Array<Highcharts.SeriesOptionsType> = [];

  const separationThreshold = getChartLineSeperationThreshold(input.intervals);
  
  particleSeries.push(
    ...input.percents
      .sort((a, b) => a - b)
      .map((percent, i): Highcharts.SeriesOptionsType => {
        if (!percents.has(percent)) return null;

        const data: [number, number][] = [];
        let prevInterval: fragQuery.timeline.sieveSizesAtPercents.Interval;
        for (const interval of input.intervals) {
          const y = interval.data[i] ?? 0;
          if (!prevInterval) {
            data.push([interval.begin.valueOf(), y]);
          } else if (
            prevInterval.end.getTime() - interval.begin.getTime() >=
            separationThreshold
          ) {
            data.push([prevInterval.end.valueOf(), 0]);
            data.push([interval.begin.valueOf(), 0]);
          }
          data.push([interval.begin.lerp(interval.end, 0.5).valueOf(), y]);
          prevInterval = interval;
        }

        return {
          type: 'spline',
          name: 'D' + percent,
          data,
          color: percents.get(percent),
          lineWidth: 4,
          yAxis: 1,
          point: {
            events: {
              click: function (event) {
                if (plotClick) {
                  const clickedXValue = this.series.xAxis.toValue(event.chartX);
                  plotClick(clickedXValue);
                }
              },
            },
          },
        };
      })
      .filter((v) => !!v)
      .reverse()
  );

  let minScore = Infinity;
  let maxScore = -Infinity;

  for (const interval of inputScore.intervals) {
    const score = Number((interval.score * 100).toFixed(2));
    const iqaScore = Number((interval.iqaScore * 100).toFixed(2));

    scoreData.push([interval.begin.valueOf(), score]);
    iqaScoreData.push([interval.begin.valueOf(), iqaScore]);

    minScore = Math.min(minScore, score, iqaScore);
    maxScore = Math.max(maxScore, score, iqaScore);
  }

  if (minScore === Infinity) {
    minScore = 0;
  }
  if (maxScore === -Infinity) {
    maxScore = 0;
  }

  scoreSeries.push({
    type: 'spline',
    name: 'AI Score',
    color: 'red',
    data: scoreData,
    yAxis: 0,
  });

  scoreSeries.push({
    type: 'spline',
    name:'Image Quality',
    color: 'blue',
    data: iqaScoreData,
    yAxis:0,
  })

  return {
    rangeSelector: { enabled: false },
    credits: { enabled: false },
    chart: {
      backgroundColor: 'transparent',
      zooming: { mouseWheel: { enabled: true } },
    },
    exporting: { enabled: false },
    navigator: { enabled: true },
    scrollbar: {
      enabled: true,
      liveRedraw: false,
      barBackgroundColor: '#5d7af6',
    },
    tooltip: { enabled: true },
    xAxis: {
      title: { text: 'Time', style: { fontSize: '1em', color: '#777' } },
      labels: { style: { color: '#777' } },
      breaks: [{ breakSize: 1000 }],
      events: {
        afterSetExtremes: function (event) {
          if (event.trigger && event.userMin === event.dataMin) {
            addData('begin');
          } else if (event.trigger && event.dataMax === event.userMax) {
            addData('end');
          }
        },
      },
    },
    yAxis: [
      {
        title: { text: 'AI Score & Image Quality (%)', style: { fontSize: '1em', color: '#777' } },
        labels: { style: { color: '#777' } },
        opposite: false,
        min: minScore - 2, 
        max: maxScore + 2, 
      },
      {
        title: {
          text: `Particle Size (${particleSizeUnit})`,
          style: { fontSize: '1em', color: '#777' },
        },
        labels: { style: { color: '#777' } },
        opposite: true,
      },
    ],
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: { fontSize: '12px', color: '#333' },
      symbolHeight: 12,
      symbolWidth: 30,
      symbolRadius: 20,
    },
    series: [...scoreSeries, ...particleSeries],
  };
};