<mat-card class="auth-card">
  <mat-card-title class="title">Sign in to your Netra account</mat-card-title>
  <hr class="title-divider" />
  <mat-card-content>
    <div class="input-wrapper">
      <div class="input-row">
        <label>Email:</label>
        <mat-form-field appearance="outline" class="input-field">
          <input matInput type="text" [(ngModel)]="email" (keyup)="loginOnEnter($event)" />
        </mat-form-field>
      </div>
      <div class="input-row">
        <label>Password:</label>
        <mat-form-field appearance="outline" class="input-field">
          <input matInput [type]="passwordVisible ? 'text' : 'password'" [(ngModel)]="password"
            (keyup)="loginOnEnter($event)" />
          <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
            <mat-icon>{{ passwordVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="errors" class="error">
      <div *ngFor="let error of errors">
        {{ error }}
      </div>
    </div>
  </mat-card-content>
  <button class="login-btn" mat-flat-button color="primary" (click)="login()">Login</button>
</mat-card>