import { Injectable, Injector, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DialogHtmlComponent } from './dialog-html/dialog-html.component';
import { DialogMessageComponent } from './dialog-message/dialog-message.component';
import { DialogReflectComponent } from './dialog-reflect/dialog-reflect.component';
import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import {DialogInputComponent} from './dialog-input/dialog-input.component';
import {DialogConfirmComponent} from './dialog-confirm/dialog-confirm.component';
import {DialogColorPickerComponent} from './dialog-color-picker/dialog-color-picker.component';
import {Color} from '@lib/color';
import { DialogAddLivetagComponent } from './dialog-add-livetag/dialog-add-livetag.component';
import { DialogReportPropertiesComponent } from './dialog-report-properties/dialog-report-properties.component';
import { DialogProgressComponent } from './dialog-progress/dialog-progress.component';
import { DialogCustomSizesComponent } from './dialog-custom-sizes/dialog-custom-sizes.component';

interface DialogComponent<Input>{
	new(...args:any[]):any;
	config:MatDialogConfig<Input>;
	output:any;
}

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	constructor(
		private readonly matDialog:MatDialog,
		private readonly snackBar:MatSnackBar,
		private readonly injector:Injector,
	){
	}

	private viewContainerRef:ViewContainerRef;

	public viewContainer(viewContainerRef:ViewContainerRef){
		if(this.viewContainerRef && viewContainerRef)
			throw Error('viewContainerRef already set');
		if(!viewContainerRef)
			viewContainerRef=undefined;
		this.viewContainerRef=viewContainerRef;
	}

	public isOpen<T>(componentType?:{new (...params:any[]):T}){
		if(componentType){
			for(let dialog of this.matDialog.openDialogs){
				if(dialog.componentInstance instanceof componentType)
					return true;
			}
			return false;
		}
		return this.matDialog.openDialogs.length>0;
	}

	public filter<T>(componentType:{new (...params:any[]):T}):T[]{
		const out:T[]=[];
		for(let dialog of this.matDialog.openDialogs){
			if(dialog.componentInstance instanceof componentType)
				out.push(dialog.componentInstance);
		}
		return out;
	}

	public find<T>(componentType:{new (...params:any[]):T}):T{
		for(let dialog of this.matDialog.openDialogs){
			if(dialog.componentInstance instanceof componentType)
				return dialog.componentInstance;
		}
		return null;
	}

	public closeAll(componentType?:any){
		if(componentType){
			this.matDialog.openDialogs.forEach(dialog=>{
				if(dialog.componentInstance instanceof componentType)
					dialog.close();
			});
		}else{
			this.matDialog.closeAll();
		}
	}

	public message(message: string){
		this.closeAll(DialogMessageComponent);
		const dialog=this.matDialog.open(DialogMessageComponent, {
			viewContainerRef: this.viewContainerRef,
			data: message
		});
		return firstValueFrom(dialog.afterClosed());
	}

	public confirm(input:DialogConfirmComponent.Input){
		this.closeAll(DialogConfirmComponent);
		const dialog=this.matDialog.open<DialogConfirmComponent,DialogConfirmComponent.Input,string>(DialogConfirmComponent, {
			viewContainerRef: this.viewContainerRef,
			data: input,
			disableClose: true,
		});
		return firstValueFrom(dialog.afterClosed());
	}

	public input(input:DialogInputComponent.Input){
		this.closeAll(DialogInputComponent);
		const dialog=this.matDialog.open<DialogInputComponent,DialogInputComponent.Input,string>(DialogInputComponent, {
			viewContainerRef: this.viewContainerRef,
			data: input,
			disableClose: true,
		});
		return firstValueFrom(dialog.afterClosed());
	}

	public html(html:string){
		this.closeAll(DialogHtmlComponent);
		const dialog=this.matDialog.open(DialogHtmlComponent, {
			viewContainerRef: this.viewContainerRef,
			data: html
		});
		return firstValueFrom(dialog.afterClosed());
	}

	public reflect(data:any){
		this.closeAll(DialogReflectComponent);
		const dialog=this.matDialog.open(DialogReflectComponent, {
			viewContainerRef: this.viewContainerRef,
			data,
			disableClose: true,
		});
		return firstValueFrom(dialog.afterClosed());
	}

	public addlivetag(){
		this.closeAll(DialogAddLivetagComponent)
		const dialog=this.matDialog.open(DialogAddLivetagComponent,{
			viewContainerRef: this.viewContainerRef
		})
		return firstValueFrom(dialog.afterClosed());
	}

	public openReportProperties(){
		this.closeAll(DialogReportPropertiesComponent)
		const dialog=this.matDialog.open(DialogReportPropertiesComponent,{
			viewContainerRef:this.viewContainerRef
		})
		return firstValueFrom(dialog.afterClosed());
	}

	public openCustomSizeSettings(){
		this.closeAll(DialogCustomSizesComponent)
		const dialog=this.matDialog.open(DialogCustomSizesComponent,{
			viewContainerRef:this.viewContainerRef
		})
		return firstValueFrom(dialog.afterClosed());
	}

	public async progress<T>(promise:Promise<T>, progress$: BehaviorSubject<number>,message$:BehaviorSubject<string>):Promise<T> {
		const dialog=this.matDialog.open(DialogProgressComponent, {
			viewContainerRef: this.viewContainerRef,
			data:{
				progress$,
				message$
			},
			disableClose: true,
		});
		const r = await promise;
		return r;
	}

	// public table<ColumnKey extends string>(input:DialogTableInputKeyed<ColumnKey>&{type:'sticky'}):DialogRef<DialogTableComponent,void>;
	// public table<ColumnKey extends string>(input:DialogTableInputKeyed<ColumnKey>&{type?:'temporary'}):Promise<void>;
	// public table(input:DialogTableInputIndexed&{type:'sticky'}):DialogRef<DialogTableComponent,void>;
	// public table(input:DialogTableInputIndexed&{type?:'temporary'}):Promise<void>;
	// public table(input:DialogTableInput){
	// 	input.type??='temporary';
	// 	const dialog=this.matDialog.open<DialogTableComponent,DialogTableInput,void>(DialogTableComponent, {
	// 		viewContainerRef: this.viewContainerRef,
	// 		data: input,
	// 		autoFocus: false,
	// 		hasBackdrop: input.type==='temporary',
	// 	});
	// 	if(input.type==='temporary')
	// 		return firstValueFrom(dialog.afterClosed());
	// 	return new DialogRef(dialog);
	// }

	public wait(){
		const dialog=this.matDialog.open(DialogSpinnerComponent, {
			viewContainerRef: this.viewContainerRef,
			disableClose: true,
			panelClass: 'transparent-spinner'
		});
		return dialog;
	}

	public async await<T>(
		promise:Promise<T>,
	):Promise<T>{
		const wait=this.wait();
		const r=await promise;
		wait.close();
		return r;
	}

	// public select<T>(options: DialogSelectData<T>) {
	// 	let dialog=this.matDialog.open<DialogSelectComponent,DialogSelectData<T>,T>(DialogSelectComponent, {
	// 		viewContainerRef: this.viewContainerRef,
	// 		data: options,
	// 		disableClose: true,
	// 	});
	// 	return firstValueFrom(dialog.afterClosed());
	// }

	// public chooseSubset<K>(input:DialogSubsetInput<Set<K>>):Promise<Set<K>>;
	// public chooseSubset<K,V>(input:DialogSubsetInput<Map<K,V>>):Promise<Map<K,V>>;
	// public chooseSubset(input:DialogSubsetInput):Promise<DialogSubsetOutput>;
	// public chooseSubset(input:DialogSubsetInput){
	// 	const dialog=this.matDialog.open<DialogSubsetComponent,DialogSubsetInput,DialogSubsetOutput>(DialogSubsetComponent, {
	// 		viewContainerRef: this.viewContainerRef,
	// 		data: input,
	// 		disableClose: true,
	// 		autoFocus: false,
	// 	});
	// 	return dialog.afterClosed().toPromise();
	// }

	public pickColor(input:DialogColorPickerComponent.Input) {
		const dialog=this.matDialog.open<
			DialogColorPickerComponent,
			DialogColorPickerComponent.Input,
			Color
		>(DialogColorPickerComponent, {
			viewContainerRef: this.viewContainerRef,
			data: input,
			disableClose: true,
		});
		return firstValueFrom(dialog.afterClosed());
	}

	// public fileInput(input:DialogFileInputData){
	// 	const dialog=this.matDialog.open<DialogFileInputComponent,DialogFileInputData,File>(DialogFileInputComponent,{
	// 		viewContainerRef: this.viewContainerRef,
	// 		data: input,
	// 		disableClose: true,
	// 	});
	// 	return firstValueFrom(dialog.afterClosed());
	// }

	// // Opens a dialog box with one text field to enter.
	// public textInput(input:DialogTextInputData) {
	// 	const dialog=this.matDialog.open( DialogTextInputComponent, {
	// 		viewContainerRef: this.viewContainerRef,
	// 		data: input,
	// 		disableClose: true
	// 	});
	// 	return firstValueFrom(dialog.afterClosed());
	// }

	// public loginInput() {
	// 	const dialog=this.matDialog.open<DialogLoginComponent,any,Boolean>( DialogLoginComponent, {
	// 		viewContainerRef: this.viewContainerRef,
	// 		disableClose: true
	// 	});
	// 	return firstValueFrom(dialog.afterClosed());
	// }

	public notify(
		message:string,
		config:MatSnackBarConfig={},
	){
		console.info('notify',message);

		config.duration??=3000;

		const ref=this.snackBar.open(message,undefined,config);
		return ref;
	}

	// public coordinateSystemPicker(input:DialogCoordinateSystemPickerInput){
	// 	const dialog=this.matDialog.open<DialogCoordinateSystemPickerComponent,DialogCoordinateSystemPickerInput,CoordinateSystem>( DialogCoordinateSystemPickerComponent, {
	// 		viewContainerRef: this.viewContainerRef,
	// 		data: input,
	// 		disableClose: true
	// 	});
	// 	return firstValueFrom(dialog.afterClosed());
	// }

	public async openTemplate<Output>(template:TemplateRef<any>, config:MatDialogConfig<any>){
		const dialog=this.matDialog.open<any,any,Output>(template,config);
		return await firstValueFrom(dialog.afterClosed());
	}
	
	public async open<Component extends DialogComponent<void>>(component:Component):Promise<Component['output']>;
	public async open<Component extends DialogComponent<any>>(component:Component, data:Component['config']['data']):Promise<Component['output']>;
	public async open<Component extends DialogComponent<any>>(
		component:Component,
		data?:Component['config']['data'],
	):Promise<Component['output']>{
		const config={...component.config};
		if(data!==undefined)
			config.data=data;
		const dialog=this.matDialog.open<Component,Component['config']['data'],any>(component,config);
		return await firstValueFrom(dialog.afterClosed());
	}	
}
