<live-tag-details [tags]="tags$ | async"> </live-tag-details>

<div class="section flex-percent flex-wrapper">
    <div class="container-67 flex-column">

        <percent-selector [value]="newPercents$ | async" (valueChanged)="newPercents$.next($event)"></percent-selector>
        <mat-card>
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        PSD Timeline
                    </ng-template>
                    <ng-container>
                        <div class="flex-row justify full-width feature-btn-container">
                            <div class="button-group left-group flex-row top"
                                [ngStyle]="{ visibility: (timelineChartType !== 'custom' || !(customTimelineChartLines$ | async)?.series?.length) ? 'hidden' : 'visible' }">

                                <mat-icon *ngIf="timelineChartType === 'custom'" (click)="openCustomSizerangeDialog()"
                                    matTooltip="Set Custom Sizes" [matTooltipPosition]="'below'"
                                    class="custom-sizes-btn">launch</mat-icon>

                                <button mat-button class="scondary-toggle-button"
                                    [class.active]="customTimelineChartType === 'size'"
                                    (click)="customTimelineChartType = 'size'">Size</button>
                                <button mat-button class="scondary-toggle-button"
                                    [class.active]="customTimelineChartType === 'range'"
                                    (click)="customTimelineChartType = 'range'">Range</button>
                            </div>

                            <div class="button-group right-group flex-row end">
                                <button mat-button class="scondary-toggle-button"
                                    [class.active]="timelineChartType === 'default'"
                                    (click)="timelineChartType = 'default'"> D Values</button>
                                <button mat-button class="scondary-toggle-button"
                                    [class.active]="timelineChartType === 'custom'"
                                    (click)="timelineChartType = 'custom'"> Custom Sizes</button>
                            </div>
                        </div>
                        <div *ngIf="(chartLines$ | async) === undefined" class="container centered-content">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(this.fragQuery.addOnTimelineSieveSizesAtPercents$|async)===undefined"
                            class="container centered-content add-on-loader">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(chartLines$ | async) === null" class="container centered-content">
                            <div class="container centered-content">
                                <div>
                                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                                </div>
                                <div>No Data Found</div>
                            </div>
                        </div>
                        <div *ngIf="timelineChartType === 'default'">
                            <div *ngIf="(chartType$ | async) === 'line'">
                                <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                    <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                        [constructorType]="'stockChart'" [options]="chartLines$|async">
                                    </highcharts-chart>
                                </figure>
                            </div>
                            <div *ngIf="(chartType$ | async) === 'series'">
                                <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                    <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                        [constructorType]="'stockChart'" [options]="chartSeries$|async">
                                    </highcharts-chart>
                                </figure>
                            </div>
                        </div>
                        <div
                        *ngIf="timelineChartType === 'custom' && (device$ | async)?.timelineCustomSizes?.length > 0">
                        <!-- No Data Found for Size -->
                        <div *ngIf="!(customTimelineChartLines$ | async)?.series?.length && !(device$ | async)?.timelineCustomSizes?.length"
                            class="container centered-content">
                            <div class="container centered-content flex-row">
                                <div>
                                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                                </div>
                                <div>Please Set Custom size first to view the chart
                                    <button *ngIf="timelineChartType === 'custom'" mat-icon-button
                                        (click)="openCustomSizerangeDialog()" matTooltip="Set Custom Sizes"
                                        [matTooltipPosition]="'below'">
                                        <mat-icon>launch</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="customTimelineChartType === 'size' && (device$ | async)?.timelineCustomSizes?.length > 0">
                            <div *ngIf="(customTimelineChartLines$ | async)?.series?.length">
                                <div *ngIf="(chartType$ | async) === 'line'">
                                    <figure *ngIf="customTimelineChartLines$ | async"
                                        class="highcharts-figure full-width">
                                        <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                            [constructorType]="'stockChart'"
                                            [options]="customTimelineChartLines$ | async">
                                        </highcharts-chart>
                                    </figure>
                                </div>
                                <div *ngIf="(chartType$ | async) === 'series'">
                                    <figure *ngIf="customTimelineChartSeries$ | async"
                                        class="highcharts-figure full-width">
                                        <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                            [constructorType]="'stockChart'"
                                            [options]="customTimelineChartSeries$ | async">
                                        </highcharts-chart>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="customTimelineChartType === 'range' && (device$ | async)?.timelineCustomSizes?.length > 0">
                            <div *ngIf="(customRangeTimelineChartLines$ | async)?.series?.length">
                                <div *ngIf="(chartType$ | async) === 'line'">
                                    <figure *ngIf="customRangeTimelineChartLines$ | async"
                                        class="highcharts-figure full-width">
                                        <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                            [constructorType]="'stockChart'"
                                            [options]="customRangeTimelineChartLines$ | async">
                                        </highcharts-chart>
                                    </figure>
                                </div>
                                <div *ngIf="(chartType$ | async) === 'series'">
                                    <figure *ngIf="customRangeTimelineChartSeries$ | async"
                                        class="highcharts-figure full-width">
                                        <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                            [constructorType]="'stockChart'"
                                            [options]="customRangeTimelineChartSeries$ | async">
                                        </highcharts-chart>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    </ng-container>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        Quantity Timeline
                    </ng-template>
                    <ng-container>
                        <div class="flex-row justify full-width feature-btn-container">
                            <div class="button-group left-group flex-row top left-group">

                                <span class="density-container"
                                    *ngIf="quantityChartType === 'payload' && (weightType$|async) === 'volume'">
                                    <b>Density: </b>
                                    <span>
                                        <measurement type="largeDensity" [unitSystem]="(quantityUnitSystem$ | async)"
                                            [value]="(device$|async).density"></measurement>
                                    </span>
                                </span>
                            </div>

                            <div class="button-group right-group flex-row end right-group">
                                <button mat-button class="scondary-toggle-button"
                                    [class.active]="quantityChartType === 'volume'"
                                    (click)="quantityChartType = 'volume'">Volume</button>

                                <button mat-button class="scondary-toggle-button"
                                    [class.active]="quantityChartType === 'payload'"
                                    (click)="quantityChartType = 'payload'">Payload</button>
                            </div>
                        </div>

                        <div *ngIf="(chartLines$ | async) === undefined" class="container centered-content">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(this.fragQuery.addOnTimelineSieveSizesAtPercents$|async)===undefined"
                            class="container centered-content add-on-loader">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(chartLines$ | async) === null" class="container centered-content">
                            <div class="container centered-content">
                                <div>
                                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                                </div>
                                <div>No Data Found</div>
                            </div>
                        </div>
                        <div *ngIf="quantityChartType === 'volume'">
                            <div *ngIf="(chartType$ | async) === 'line'">
                                <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                    <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                        [constructorType]="'stockChart'" [options]="quantityChartLines$|async">
                                    </highcharts-chart>
                                </figure>
                            </div>
                            <div *ngIf="(chartType$ | async) === 'series'">
                                <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                    <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                        [constructorType]="'stockChart'" [options]="quantityChartSeries$|async">
                                    </highcharts-chart>
                                </figure>
                            </div>
                        </div>
                        <div *ngIf="quantityChartType === 'payload'">
                            <div *ngIf="(chartType$ | async) === 'series'">
                                <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                    <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                        [constructorType]="'stockChart'" [options]="payloadChartSeries$|async">
                                    </highcharts-chart>
                                </figure>
                            </div>
                            <div *ngIf="(chartType$ | async) === 'line'">
                                <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                    <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts"
                                        [constructorType]="'stockChart'" [options]="payloadChartLines$|async">
                                    </highcharts-chart>
                                </figure>
                            </div>
                        </div>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
    <div class="container container-33">
        <snapshot-card-analysis [series]="chartInputs$ | async" [currInterval]="currentInterval | async">
        </snapshot-card-analysis>
    </div>
</div>