import { Component } from '@angular/core';
import { environment } from '@environment';
import { User$ } from '@injectables';
import { LoginService } from '@injectables';
@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

    public showVideoTab: boolean = environment.showVideoTab;
    
    constructor(public readonly user$: User$,
        private LoginService: LoginService,
    ) {}

    public isClientAdmin = this.LoginService.isUserClientAdmin();
    public isSuperAdmin = this.LoginService.isUserSuperAdmin();
}