<div mat-dialog-content class="container">
	<div *ngIf="input.title">
		{{ input.title }}
	</div>
	<div class="text-center container">
		{{ input.message }}
	</div>
</div>
<div mat-dialog-actions class="flex-row justify">
	<button mat-button [mat-dialog-close]="false">Cancel</button>
	<button mat-flat-button color="primary" [mat-dialog-close]="true">Confirm</button>
</div>