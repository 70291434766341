import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { WebClient } from '@injectables';
import { Device, User } from '@models';
import { timezonesData, dateFormats } from './preference-list';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'preferences',
    templateUrl: './user-preferences.component.html',
    styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnInit {
    constructor(
        private formBuilder: FormBuilder,
        private readonly webClient: WebClient,
        public readonly user: User,
        private fragQuery: FragQueryService,
        private readonly dialog: DialogService,
        private readonly snackBar:MatSnackBar
    ) { }

    public breakpoint: number;
    public formGroup: FormGroup;
    public readonly devices$ = this.fragQuery.devices$;
    public readonly device$ = this.fragQuery.device$;
    public readonly timezonesData = timezonesData;
    public readonly dateFormats = dateFormats;
    public readonly restClient = this.webClient.model.user;
    public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
    public showAddSizeRange: boolean = false;
    public sizeOptions: Array<{ minSize: number, maxSize: number, intervals: number }> = [];

    ngOnInit(): void {
        try {
            let customSizeOptions = JSON.parse(this.user.customSizeRanges)
            if(customSizeOptions[0].minSize!=null && customSizeOptions[0].maxSize!=null){
                this.sizeOptions = customSizeOptions;
            }
        } catch (e) {
            this.sizeOptions = [
                { minSize: this.user.minTargetSize, maxSize: this.user.maxTargetSize, intervals: this.user.targetIntervals }
            ];
        }
        this.formGroup = this.formBuilder.group({
            cameraSpeed: [this.user.cameraSpeed],
            sieveSize: [this.user.sieveSize],
            particleSizeDistributionModel: [this.user.particleSizeDistributionModel],
            timezone: this.user.timezone,
            particleSizeUnit: this.user.particleUnit,
            quantityUnit: this.user.quantityUnit,
            dateFormat: [this.user.dateFormat],
            timeFormat: [this.user.timeFormat],
            minTargetSize: [this.user.minTargetSize],
            maxTargetSize: [this.user.maxTargetSize],
            targetIntervals: [this.user.targetIntervals],
            dailySummary: this.user.dailySummaryMail,
            newMinSize: [null],
            newMaxSize: [null],
            newTargetInterval: [null],
            preferCustomSizes:[this.user.preferCustomSizes],
            intervals: [''],
        });
        this.breakpoint = window.innerWidth <= 600 ? 1 : 2;
    }

    timezoneOffsetAvailable(): boolean {
        if (this.formGroup.get('timezone').value && timezonesData.tz2.find(timezone => timezone.value === this.formGroup.get('timezone').value)?.offset) {
            return true;
        } else {
            return false;
        }
    }

    onSizeRangeChange(selectedValue: { minSize: number, maxSize: number, intervals: number }) {
        this.formGroup.patchValue({
            minTargetSize: selectedValue.minSize,
            maxTargetSize: selectedValue.maxSize,
            newTargetInterval: selectedValue.intervals
        });
    }

    toggleAddSizeRange() {
        this.showAddSizeRange = !this.showAddSizeRange;
    }

    addCustomSizeRange() {
        const minSize = this.formGroup.get('newMinSize').value;
        const maxSize = this.formGroup.get('newMaxSize').value;
        const intervals = this.formGroup.get('newTargetInterval').value;
        if (minSize!= null && maxSize && minSize < maxSize) {
            const newRange = { minSize: minSize, maxSize: maxSize, intervals: intervals };
            this.sizeOptions = [...this.sizeOptions, newRange];
            this.formGroup.get('newMinSize').setValue(null);
            this.formGroup.get('newMaxSize').setValue(null);
            this.formGroup.get('newTargetInterval').setValue(null);
        } else {
            alert('Invalid size range');
        }
    }

    removeSizeRange(rangeToRemove: { minSize: number; maxSize: number; intervals: number }) {
        this.sizeOptions = this.sizeOptions.filter(range => range !== rangeToRemove);
    }

    async saveSettings() {
        this.user.cameraSpeed = this.formGroup.get('cameraSpeed').value;
        this.user.sieveSize = this.formGroup.get('sieveSize').value;
        this.user.particleSizeDistributionModel = this.formGroup.get('particleSizeDistributionModel').value;
        this.user.timezone = this.formGroup.get('timezone').value;
        this.user.dateFormat = this.formGroup.get('dateFormat').value;
        this.user.timeFormat = this.formGroup.get('timeFormat').value;
        this.user.particleUnit = this.formGroup.get('particleSizeUnit').value;
        this.user.quantityUnit = this.formGroup.get('quantityUnit').value;
        this.user.dailySummaryMail = this.formGroup.get('dailySummary').value;
        this.user.minTargetSize = this.formGroup.get('minTargetSize').value;
        this.user.maxTargetSize = this.formGroup.get('maxTargetSize').value;
        this.user.targetIntervals = this.formGroup.get('targetIntervals').value;
        this.user.preferCustomSizes= this.formGroup.get('preferCustomSizes').value;
        this.user.customSizeRanges = JSON.stringify(this.sizeOptions);
        if (this.timezoneOffsetAvailable()) {
            this.user.timezoneOffset = timezonesData.tz2.find(timezone => timezone.value === this.formGroup.get('timezone').value).offset * 60;
        } else {
            this.user.timezoneOffset = (new Date().getTimezoneOffset() * -1);
        }

        try {
            await this.dialog.await(this.restClient.save(this.user));
            this.snackBar.open('Settings saved successfully', 'OK', {
                duration: 5000,
                panelClass: ['snackbar-success']
            });
            this.fragQuery.trigger$.next();
        } catch (error) {
            console.error('Error saving settings:', error);
        }
    }
}
