import { Component, Input, OnInit } from '@angular/core';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { BehaviorSubject } from 'rxjs';
import { CsvUtilService } from 'src/injectables/csv-util.service';
import { op } from '@lib/rxjs';
import { NavigationRoute$ } from '@injectables';

@Component({
  selector: 'dialog-csv-options',
  templateUrl: './dialog-csv-options.html',
  styleUrls: ['./dialog-csv-options.scss'],
})
export class DialogCsvOptionsComponent implements OnInit {
  @Input() fileType: 'single-row' | 'multiple-row' = 'single-row';
  public particleSizeUnit: string;
  public weightTypeTitle: string;
  public totalPayload: number;
  private deviceName: string = '';
  csvDataReady$ = new BehaviorSubject<boolean>(false);
  multiRowCheck: Boolean = false;
  options: string[] = ['Single Row', 'Multiple Rows'];
  psdDataFormatInCSV: 'Single Row' | 'Multiple Rows' = 'Single Row';
  componentName: string;

  public readonly type$ = this.route$.pipe(op.map((route) => route[0]));


  constructor(
    private readonly fragQuery: FragQueryService,
    private readonly csvDownloadService: CsvUtilService,
    private readonly route$: NavigationRoute$,

  ) { }

  ngOnInit() {
    this.fragQuery.particleSizeUnit$.subscribe((unit: string) => {
      this.particleSizeUnit = unit;
    }),
      this.fragQuery.QuantityTitle$.subscribe((title: string) => {
        this.weightTypeTitle = title;
      }),
      this.fragQuery.totalPayload$.subscribe((payload: number) => {
        this.totalPayload = payload;
      }),
      this.fragQuery.device$.subscribe(
        device => { this.deviceName = device.name }
      )
  }

  async downloadCsv() {
    await this.csvDownloadService.downloadCsv(
      this.fileType,
      this.particleSizeUnit,
      this.weightTypeTitle,
      this.totalPayload,
      this.deviceName,
      this.psdDataFormatInCSV
    );
  }
}
